<template>
    <div class="set">
        <div class="set-left">
            <div class="user-photo">
                <div :style="'background-image:url(' + store.state.user.photo + ')'" class="avatar"></div>
            </div>
            <div class="user-name">
                <el-text>{{ store.state.user.username }}</el-text>
            </div>
            <el-divider />
            <div class="button-list">
                <RouterLink :to="{ name: 'setaccount' }">
                    <div class="account-button">
                        <el-button text>
                            <svg t="1701494329341" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="4251" width="25" height="25">
                                <path
                                    d="M867.584 160.192c-149.632-16.928-262.208-57.408-334.592-120.352l-19.04-16.544-20.544 14.656C379.968 118.944 267.776 160 160 160H128v448c0 137.344 121.088 261.92 370.208 380.864l13.088 6.24 13.344-5.728C771.072 883.52 896 755.232 896 608V163.424l-28.416-3.232zM832 608c0 116.8-107.392 223.36-319.328 316.8C299.872 821.024 192 714.464 192 608V222.976c104.672-6.784 211.584-46.688 318.496-118.944C587.232 162.528 695.168 201.536 832 220.256V608z"
                                    p-id="4252" fill="#8a8a8a"></path>
                                <path
                                    d="M359.776 468.672a32 32 0 1 0-47.968 42.4l121.792 137.824c12.608 14.24 30.176 21.568 47.904 21.568a64.384 64.384 0 0 0 49.696-23.52l197.6-242.72a32 32 0 0 0-49.632-40.416l-197.6 242.688-121.792-137.824z"
                                    p-id="4253" fill="#8a8a8a"></path>
                            </svg>
                            <el-text style="margin-left: 15px;">
                                账号安全
                            </el-text>
                        </el-button>
                    </div>
                </RouterLink>
                <RouterLink :to="{ name: 'setinfo' }">
                    <div class="info-button">
                        <el-button text>
                            <svg t="1701494854412" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="5373" width="25" height="25">
                                <path
                                    d="M918.84347 765.702891h-255.234298c-14.293121 0-25.52343-11.230309-25.523429-25.523429s11.230309-25.52343 25.523429-25.52343h255.234298c13.782652 0 25.52343-8.677966 25.523429-19.397807V70.444666c0-10.71984-11.740778-19.397807-25.523429-19.397807H408.374875c-13.782652 0-25.52343 8.677966-25.523429 19.397807v169.986042c0 14.293121-11.230309 25.52343-25.52343 25.52343s-25.52343-11.230309-25.52343-25.52343V70.444666C331.804586 31.649053 366.005982 0 408.374875 0h510.468595c42.368893 0 76.570289 31.649053 76.570289 70.444666v625.324028c0 38.285145-34.201396 69.934197-76.570289 69.934197z"
                                    fill="#8a8a8a" p-id="5374"></path>
                                <path
                                    d="M852.993021 255.234297H485.966102c-14.293121 0-25.52343-11.230309-25.52343-25.52343s11.230309-25.52343 25.52343-25.523429h367.026919c14.293121 0 25.52343 11.230309 25.52343 25.523429s-11.230309 25.52343-25.52343 25.52343z"
                                    fill="#8a8a8a" p-id="5375"></path>
                                <path
                                    d="M852.993021 408.374875H587.038883c-14.293121 0-25.52343-11.230309-25.523429-25.523429s11.230309-25.52343 25.523429-25.52343h265.954138c14.293121 0 25.52343 11.230309 25.52343 25.52343s-11.230309 25.52343-25.52343 25.523429z"
                                    fill="#8a8a8a" p-id="5376"></path>
                                <path
                                    d="M852.993021 561.515454h-183.258225c-14.293121 0-25.52343-11.230309-25.52343-25.52343s11.230309-25.52343 25.52343-25.52343h183.258225c14.293121 0 25.52343 11.230309 25.52343 25.52343s-11.230309 25.52343-25.52343 25.52343z"
                                    fill="#8a8a8a" p-id="5377"></path>
                                <path
                                    d="M331.804586 740.179462c-112.813559 0-204.187438-91.373878-204.187437-204.187438s91.373878-204.187438 204.187437-204.187438 204.187438 91.373878 204.187438 204.187438-91.373878 204.187438-204.187438 204.187438z m0-357.328016c-84.227318 0-153.140578 68.91326-153.140578 153.140578s68.91326 153.140578 153.140578 153.140578 153.140578-68.91326 153.140579-153.140578-68.91326-153.140578-153.140579-153.140578z"
                                    fill="#8a8a8a" p-id="5378"></path>
                                <path
                                    d="M638.085743 1024l-27.565304-3.062812c-23.481555-1.020937-477.798604-1.020937-558.963111 0l-26.033898 0.510469v-26.033898c0-168.965105 137.316052-306.281157 306.281156-306.281157s306.281157 137.316052 306.281157 306.281157v28.586241z m-306.281157-283.820538c-132.211366 0-240.941176 100.562313-254.21336 229.710867 100.051844-0.510469 409.906281-1.020937 507.916252 0a254.928016 254.928016 0 0 0-253.702892-229.710867z"
                                    fill="#8a8a8a" p-id="5379"></path>
                            </svg>
                            <el-text style="margin-left: 15px;">
                                个人资料
                            </el-text>
                        </el-button>
                    </div>
                </RouterLink>
                <el-divider />
                <div class="problem-button">
                    <el-button text>
                        <svg t="1701495914011" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="9485" width="25" height="25">
                            <path
                                d="M512 127.168l-62.912 1.6c-216.128 15.84-387.2 195.84-387.2 416.032 0 230.624 186.656 417.568 417.312 417.568 220.224 0 400-171.456 415.936-387.584L896.512 512 512 512 512 127.168zM833.024 576c-15.648 192-168.096 324.672-354.112 324.672-196.448 0-355.744-159.36-355.744-355.808C123.168 358.848 256 206.304 448 190.624L448 576 833.024 576zM544 65.344 544 480l414.368 0C958.368 256 768 65.344 544 65.344zM608 131.872c32 4.576 49.44 12.192 74.4 22.752 42.432 17.92 79.04 43.616 111.776 76.352 32.736 32.736 57.696 68.032 75.648 110.464 10.56 24.96 17.408 42.56 22.016 74.56L608 416 608 131.872z"
                                fill="#8a8a8a" p-id="9486"></path>
                        </svg>
                        <el-text style="margin-left: 15px;">
                            做题分析
                        </el-text>
                    </el-button>
                </div>
                <div class="code-button">
                    <el-button text>
                        <svg t="1701858990362" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4581" width="25" height="25">
                            <path
                                d="M832 128H192c-35.328 0-64 28.672-64 64v640c0 35.328 28.672 64 64 64h640c35.328 0 64-28.672 64-64V192c0-35.328-28.672-64-64-64zM395.776 626.688l-45.056 45.056-158.208-158.208 45.056-45.056L350.72 355.328l45.056 45.056-113.152 113.152 113.152 113.152zM480.256 768h-64l128-512h64l-128 512z m193.536-96.256l-45.056-45.056 113.152-113.152-113.152-113.152 45.056-45.056L786.944 468.48 832 513.536 673.792 671.744z"
                                fill="#8a8a8a" p-id="4582"></path>
                        </svg>
                        <el-text style="margin-left: 15px;">
                            代码模板
                        </el-text>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="set-right">
            <div class="set-content">
                <el-card class="content-card">
                    <el-text style="color: black;font-weight: bold;font-size: medium;">账号信息</el-text>
                    <el-divider />
                    <div class="set-list">
                        <div class="account">
                            <el-text style="width: 80px;">
                                账号
                            </el-text>
                            <el-text>{{ store.state.user.account }}</el-text>
                        </div>
                        <el-divider />
                        <div class="email-set">
                            <div style="display: flex;align-items: center;">
                                <el-text style="width: 80px;">
                                    电子邮箱
                                </el-text>
                                <el-text>{{ store.state.user.email }}</el-text>
                            </div>

                            <div style="display: flex;align-items: center;">
                                <svg t="1701497486661" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="10552" width="25" height="25">
                                    <path
                                        d="M784.9 925.8H239.1c-77.7 0-140.9-63.2-140.9-140.9V239.1c0-77.7 63.2-140.9 140.9-140.9H512c27.5 0 49.9 22.3 49.9 49.9S539.6 198 512 198H239.1c-22.7 0-41.2 18.5-41.2 41.2v545.7c0 22.7 18.5 41.2 41.2 41.2h545.7c22.7 0 41.2-18.5 41.2-41.2V512c0-27.6 22.3-49.9 49.9-49.9 27.5 0 49.9 22.3 49.9 49.9v272.8c0 77.8-63.3 141-140.9 141z"
                                        p-id="10553" fill="#8a8a8a"></path>
                                    <path
                                        d="M427.6 646.3c-12.8 0-25.5-4.9-35.3-14.6-19.5-19.5-19.5-51 0-70.5L816.5 137c19.5-19.5 51-19.5 70.5 0s19.5 51 0 70.5L462.8 631.7c-9.7 9.7-22.5 14.6-35.2 14.6z"
                                        p-id="10554" fill="#8a8a8a"></path>
                                </svg>
                                <el-button style="margin-left: 5px;" text @click="updateEmailinput = true">修改</el-button>
                                <el-dialog v-model="updateEmailinput" style="width: 400px;" title="修改邮箱">
                                    <div style="display: flex;flex-direction: column;" v-if="!lockEmail">
                                        <div style="display: flex;align-content: center;">
                                            <el-text style="width: 80px;">电子邮箱</el-text>
                                            <el-text>{{ store.state.user.email }}</el-text>
                                        </div>
                                        <div style="display: flex;align-content: center;margin-top: 20px;">
                                            <el-text style="width: 80px;">验证码</el-text>
                                            <el-input v-model="LastCode.code" style="width: auto;"></el-input>
                                            <el-button @click="sendLastEmail" style="width: 80px;" :loading="LastLoading"
                                                text>发送</el-button>
                                        </div>
                                        <div style="margin-top: 40px;display: flex;justify-content: center;">
                                            <el-button style="width: 150px;" type="success"
                                                @click="UpLockEmail">验证邮箱</el-button>
                                        </div>
                                    </div>

                                    <div style="display: flex;flex-direction: column;" v-else>
                                        <div style="display: flex;align-content: center;">
                                            <el-text style="width: 80px;">电子邮箱</el-text>
                                            <el-input v-model="updateEmail" style="width: 190px;"></el-input>
                                        </div>
                                        <div style="display: flex;align-content: center;margin-top: 20px;">
                                            <el-text style="width: 80px;">验证码</el-text>
                                            <el-input v-model="NowCode.code" style="width: auto;"></el-input>
                                            <el-button @click="sendNowEmail" style="width: 80px;" :loading="nowLoading"
                                                text>发送</el-button>
                                        </div>
                                        <div style="margin-top: 40px;display: flex;justify-content: center;">
                                            <el-button style="width: 150px;" type="danger"
                                                @click="ToupdateEmail">修改</el-button>
                                        </div>
                                    </div>
                                </el-dialog>
                            </div>
                        </div>
                        <el-divider />
                        <div class="password-set">
                            <div style="display: flex;align-items: center;">
                                <el-text style="width: 80px;">
                                    密码
                                </el-text>
                                <el-text>********</el-text>
                            </div>

                            <div style="display: flex;align-items: center;">
                                <svg t="1701497486661" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="10552" width="25" height="25">
                                    <path
                                        d="M784.9 925.8H239.1c-77.7 0-140.9-63.2-140.9-140.9V239.1c0-77.7 63.2-140.9 140.9-140.9H512c27.5 0 49.9 22.3 49.9 49.9S539.6 198 512 198H239.1c-22.7 0-41.2 18.5-41.2 41.2v545.7c0 22.7 18.5 41.2 41.2 41.2h545.7c22.7 0 41.2-18.5 41.2-41.2V512c0-27.6 22.3-49.9 49.9-49.9 27.5 0 49.9 22.3 49.9 49.9v272.8c0 77.8-63.3 141-140.9 141z"
                                        p-id="10553" fill="#8a8a8a"></path>
                                    <path
                                        d="M427.6 646.3c-12.8 0-25.5-4.9-35.3-14.6-19.5-19.5-19.5-51 0-70.5L816.5 137c19.5-19.5 51-19.5 70.5 0s19.5 51 0 70.5L462.8 631.7c-9.7 9.7-22.5 14.6-35.2 14.6z"
                                        p-id="10554" fill="#8a8a8a"></path>
                                </svg>
                                <el-button style="margin-left: 5px;" text @click="updatePasswordinput = true">修改</el-button>
                            </div>
                            <el-dialog v-model="updatePasswordinput" title="密码修改" style="width: 400px;">
                                <div style="display: flex;flex-direction: column;">
                                    <div style="display: flex;">
                                        <el-text style="width: 80px;">新密码</el-text>
                                        <el-input v-model="updatePassword" style="width: auto" type="password"
                                            show-password></el-input>
                                        <el-text :style="passwordStrength == '强密码' ? 'color:green' : 'color:red'"
                                            style="margin-left: 5px;">
                                            {{ passwordStrength }}</el-text>
                                    </div>
                                    <div style="display: flex;align-content: center;margin-top: 20px;">
                                        <el-text style="width: 80px;">验证码</el-text>
                                        <el-input v-model="passwordCode.code" style="width: auto;"></el-input>
                                        <el-button @click="sendPasswordEmail" style="width: 80px;"
                                            :loading="passwordLoading" text>发送</el-button>
                                    </div>
                                    <div style="margin-top: 40px;display: flex;justify-content: center;">
                                        <el-button style="width: 150px;" type="danger"
                                            @click="ToupdatePassword">修改</el-button>
                                    </div>
                                </div>
                            </el-dialog>
                        </div>

                        <el-divider />

                        <el-button type="danger" plain style="margin-top: 30px;" @click="del">
                            注销账号
                        </el-button>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>


<script setup>
import store from '@/store';
import { reactive, ref, watch, watchEffect } from 'vue';
import $ from 'jquery'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus';

const updateEmail = ref(null)
const LastEmail = ref(null)

watchEffect(() => {
    if (store.state.user) {
        LastEmail.value = store.state.user.email;
        updateEmail.value = store.state.user.email
    }
})
const updateEmailinput = ref(false)

const updatePassword = ref();
const updatePasswordinput = ref(false);

const lockEmail = ref(false);

const checkPasswordStrength = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[`~!@#$%^&*()-=_+;':",./<>?])(?=\S+$).{6,16}$/;

    if (password == "") {
        return "";
    }

    if (passwordRegex.test(password)) {
        return "强密码";
    } else {
        return "弱密码";
    }
}

const passwordStrength = ref("");

watch(updatePassword, () => {
    passwordStrength.value = checkPasswordStrength(updatePassword.value)
})

const lockEmailBe = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/lock/be",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        type: "get",
        data: {
            email: LastEmail.value
        },
        success(resp) {
            lockEmail.value = resp
        },
        error() {
            open("请求失败", "error");
        }
    })
}

lockEmailBe();

const LastCode = reactive({
    code: "",
    key: ""
})

const NowCode = reactive({
    code: "",
    key: ""
})

const passwordCode = reactive({
    code: "",
    key: ""
})

const LastLoading = ref(false);
const nowLoading = ref(false);
const passwordLoading = ref(false);

const sendLastEmail = () => {
    LastLoading.value = true;
    let countdownTime = 60;

    // 创建一个定时器，每秒减少一秒
    let countdown = setInterval(function () {

        // 判断倒计时是否结束
        if (countdownTime <= 0) {

            // 清除定时器
            clearInterval(countdown);

            // 倒计时结束的操作
            LastLoading.value = false;
        }

        // 减少倒计时时间
        countdownTime--;
    }, 1000);
    $.ajax({
        url: "https://staroj.top:3010/User/sendEmail",
        type: "get",
        data: {
            email: LastEmail.value
        },
        success(resp) {
            if (resp.message == "success") {
                LastCode.key = resp.key;
                open("发送成功", "success");
            } else {
                open("发送失败", "warning");
            }

        },
        error() {
            open("发送失败", "error")
        }
    })
}

const UpLockEmail = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/lock",
        type: "post",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: {
            email: LastEmail.value,
            key: LastCode.key,
            code: LastCode.code
        },
        success(resp) {
            if (resp.message == "success") {
                notice("通知", "邮箱已解锁,请在五分钟之内完成邮箱修改");
                lockEmailBe();
                LastCode.code = "";
            } else {
                open(resp.message, "warning");
            }
        },
        error() {
            open("解锁失败", "error");
        }
    })
}

const sendNowEmail = () => {
    nowLoading.value = true;
    let countdownTime = 60;

    // 创建一个定时器，每秒减少一秒
    let countdown = setInterval(function () {

        // 判断倒计时是否结束
        if (countdownTime <= 0) {

            // 清除定时器
            clearInterval(countdown);

            // 倒计时结束的操作
            nowLoading.value = false;
        }

        // 减少倒计时时间
        countdownTime--;
    }, 1000);
    $.ajax({
        url: "https://staroj.top:3010/User/sendEmail",
        type: "get",
        data: {
            email: updateEmail.value
        },
        success(resp) {
            if (resp.message == "success") {
                NowCode.key = resp.key;
                open("发送成功", "success");
            } else {
                open("发送失败", "warning");
            }

        },
        error() {
            open("发送失败", "error")
        }
    })
}

const sendPasswordEmail = () => {
    passwordLoading.value = true;
    let countdownTime = 60;

    // 创建一个定时器，每秒减少一秒
    let countdown = setInterval(function () {

        // 判断倒计时是否结束
        if (countdownTime <= 0) {

            // 清除定时器
            clearInterval(countdown);

            // 倒计时结束的操作
            passwordLoading.value = false;
        }

        // 减少倒计时时间
        countdownTime--;
    }, 1000);
    $.ajax({
        url: "https://staroj.top:3010/User/sendEmail",
        type: "get",
        data: {
            email: LastEmail.value
        },
        success(resp) {
            if (resp.message == "success") {
                passwordCode.key = resp.key;
                open("发送成功", "success");
            } else {
                open("发送失败", "warning");
            }

        },
        error() {
            open("发送失败", "error")
        }
    })
}

const ToupdateEmail = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/update/email",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        type: 'post',
        data: {
            email: updateEmail.value,
            key: NowCode.key,
            code: NowCode.code
        },
        success(resp) {
            if (resp.message == "success") {
                open("修改成功", "success");
                updateEmailinput.value = false;
                NowCode.code = "";
                LastEmail.value = JSON.parse(JSON.stringify(updateEmail.value));
                store.dispatch("getinfo", {
                    success() {
                    },
                    error() {
                    }
                })
            } else {
                open(resp.message, "warning");
            }
        },
        error() {
            open("修改失败", "error");
        }
    })
}

const ToupdatePassword = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/update/password",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        type: "post",
        data: {
            email: LastEmail.value,
            key: passwordCode.key,
            code: passwordCode.code,
            password: updatePassword.value
        },
        success(resp) {
            if (resp.message == "success") {
                open("修改成功", "success");
            } else {
                open(resp.message, "warning");
            }
            passwordCode.code = "";
            updatePassword.value = "";
            passwordStrength.value = "";
            passwordLoading.value = false;
            updatePasswordinput.value = false;
        },
        error() {
            open("修改失败", "error");
        }
    })
}

const del = () => {
    ElMessageBox.confirm(
        "注销后,你将会被封号",
        "Warning",
        {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
        }
    )
        .then(() => {
            $.ajax({
                url: "https://staroj.top:3010/User/update/delete",
                type: "get",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwt_token"),
                },
                success(resp) {
                    if (resp) {
                        store.dispatch("logout");
                        notice("通知", "你已注销成功,如需恢复,请联系管理员")
                    } else {
                        open("注销失败", "warning");
                    }
                },
                error() {
                    open("注销失败", "error");
                }
            })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '注销已取消',
            })
        })
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}

const notice = (title, message) => {
    ElNotification({
        title: title,
        message: message
    })
}
</script>

<style lang="less" scoped>
.set {
    padding-top: 100px;
    padding-left: 20%;
    padding-right: 20%;

    display: flex;

    .set-left {

        width: 15%;

        .user-photo {

            display: flex;
            align-items: center;
            justify-content: center;

            .avatar {
                border-radius: 30%;
                width: 100px;
                height: 100px;
                background-size: cover;
                font-size: 0;
            }
        }

        .user-name {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: large;
        }

        .button-list {
            .account-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .info-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .problem-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .code-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }
        }


    }

    .set-right {
        margin-left: 5%;
        width: 80%;

        .set-content {

            .content-card {
                border-radius: 10px;

                .set-list {
                    margin-top: 10px;

                    .account {
                        display: flex;

                        align-items: center;
                    }

                    .email-set {
                        display: flex;

                        align-items: center;
                        justify-content: space-between;
                    }

                    .password-set {
                        display: flex;

                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .set {

        padding-left: 0%;
        padding-right: 0%;

        .set-left {
            display: none;
        }

        .set-right {
            width: 100%;
            margin-left: 1%;
            margin-right: 1%;
        }
    }
}
</style>