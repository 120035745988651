<template>
    <svg class="logo">

        <!-- 字体动画特效 -->
        <defs>
            <linearGradient id="geekColor" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="#ffffff">
                    <animate attributeName="stop-color" values="#ffffff;#12cad6;#ffffff" dur="10s" begin="3s"
                        repeatCount="indefinite"></animate>
                </stop>
                <stop offset="100%" stop-color="#12cad6">
                    <animate attributeName="stop-color" values="#12cad6;#ffffff,#12cad6" dur="10s" begin="3s"
                        repeatCount="indefinite"></animate>
                </stop>
            </linearGradient>
        </defs>

        <!-- 字体 -->
        <text class="logoText" x="15" y="45">
            <slot></slot>
        </text>
    </svg>
</template>
<style>
.logo {
    width: 150px;
    height: 50px;
}

.logoText {
    position: absolute;
    font-family: "PingFang SC";
    font-size: 40px;
    fill: none;
    stroke: url(#geekColor);
    stroke-width: 1;
    stroke-dasharray: 10 800;
    stroke-dashoffset: 10;
    animation: 3s linear 0s drawing forwards;
}

@keyframes drawing {
    to {
        stroke-dasharray: 800;
        stroke-dashoffset: 0;
    }
}
</style>