<template>
    <div class="releasepage">
        <el-card class="content-card">
            <div class="title">
                <h1>Star Oj 1.0 预发布版本</h1>
            </div>
            <div class="content">
                <Markdown :source="releasecontent" style="min-height: 500px;width: 100%;" />
            </div>
        </el-card>
    </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it'
import { ref } from 'vue'
const releasecontent = ref("# 预发布版本 \n 这个版本很多功能还需要更多的完善，作为策划")
</script>

<style lang="less" scoped>
.releasepage {
    width: 100%;
    height: 100%;
    padding-top: 80px;

    display: flex;
    justify-content: center;

    .content-card {
        width: 80%;
        margin-top: 10px;

        .title {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .content {
            width: 100%;
            height: 100%;
        }
    }
}
</style>