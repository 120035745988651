import {
    createApp,
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/css/github-markdown.css'

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@/assets/css/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});
VueMarkdownEditor.use(createKatexPlugin());
const app = createApp(App)


app.use(store)
app.use(router)

app.use(ElementPlus)
app.use(VueMarkdownEditor)

app.mount('#app')