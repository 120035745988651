<template>
    <div class="administrator">
        <div class="administrator-left">
            <el-button circle type="success" class="open-drawer" @click="drawer = true" style="width: 32px;height: 32px;">
                <svg t="1703312654342" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="5155" width="32" height="32">
                    <path
                        d="M170.666667 392.533333L349.866667 213.333333l29.866666 29.866667-149.333333 149.333333h669.866667v42.666667H128l42.666667-42.666667z m682.666666 213.333334l-179.2 179.2-29.866666-29.866667 149.333333-149.333333H132.266667v-42.666667H896l-42.666667 42.666667z"
                        fill="#e6e6e6" p-id="5156"></path>
                </svg>
            </el-button>
            <el-drawer v-model="drawer" title="设置" direction="ltr" class="drawer" :size="200">
                <div class="drawer-avatar">
                    <div class="user-photo">
                        <div :style="'background-image:url(' + store.state.user.photo + ')'" class="avatar"></div>
                    </div>
                    <div class="user-name">
                        <el-text>{{ store.state.user.username }}</el-text>
                    </div>
                </div>
                <div class="drawer-list">
                    <el-button class="user-list" @click="pagestate = false;" text v-if="(store.state.user.authen & 1) != 0">
                        <svg t="1703314498181" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="6632" width="25" height="25">
                            <path
                                d="M767.23 482.61c35.1-31.02 57.35-75.93 57.35-126 0-86.84-65.08-158.94-151.38-167.72-17.47-1.77-33.28 11.03-35.08 28.61-1.78 17.58 11.03 33.28 28.61 35.06 53.5 5.45 93.84 50.17 93.84 104.05 0 53.31-39.88 97.98-92.75 103.92-11.64 1.3-20.63 8.96-25.12 18.91-0.04 0.08-0.08 0.15-0.12 0.22-1.24 2.8-1.94 5.7-2.33 8.82-0.2 1.16-0.82 2.15-0.88 3.35-0.05 0.89 0.35 1.66 0.37 2.53 0.03 0.53-0.19 1-0.13 1.53 0.09 0.8 0.53 1.44 0.68 2.21 2.18 14.73 13.84 26.58 29.28 27.44 119.3 6.59 216.69 101.45 226.55 220.66 1.38 16.72 15.38 29.36 31.86 29.36 0.88 0 1.77-0.03 2.67-0.11 17.61-1.45 30.7-16.91 29.25-34.53-9.75-117.84-87.27-216.63-192.67-258.31z"
                                fill="#333333" p-id="6633"></path>
                            <path
                                d="M536.12 499.11c44.15-35.34 72.55-89.57 72.55-150.39 0-106.25-86.44-192.69-192.67-192.69s-192.67 86.44-192.67 192.69c0 60.81 28.4 115.05 72.55 150.39C170 545.37 75.68 661.55 64.11 801.33c-1.45 17.61 11.64 33.08 29.25 34.53 18.06 1.56 33.08-11.66 34.53-29.25C140.19 657.89 266.75 541.39 416 541.39s275.81 116.5 288.11 265.22c1.38 16.72 15.38 29.36 31.86 29.36 0.88 0 1.77-0.03 2.67-0.11 17.61-1.45 30.7-16.92 29.25-34.53C756.32 661.55 662 545.37 536.12 499.11zM287.33 348.72c0-70.95 57.72-128.69 128.67-128.69s128.67 57.73 128.67 128.69S486.95 477.39 416 477.39s-128.67-57.72-128.67-128.67z"
                                fill="#333333" p-id="6634"></path>
                        </svg>
                        <el-text style="margin-left: 5px;">
                            用户管理
                        </el-text>
                    </el-button>
                    <el-button class="problem-list" @click="pagestate = true;" text
                        v-if="(store.state.user.authen & 2) != 0">
                        <svg t="1703314575386" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="7895" width="25" height="25">
                            <path
                                d="M811.115789 1005.136842H212.884211a194.290526 194.290526 0 0 1-194.021053-194.021053V212.884211A194.290526 194.290526 0 0 1 212.884211 18.863158h598.231578a194.290526 194.290526 0 0 1 194.021053 194.021053v598.231578a194.290526 194.290526 0 0 1-194.021053 194.021053zM212.884211 72.757895a140.126316 140.126316 0 0 0-140.126316 140.126316v598.231578a140.126316 140.126316 0 0 0 140.126316 140.126316h598.231578a140.126316 140.126316 0 0 0 140.126316-140.126316V212.884211a140.126316 140.126316 0 0 0-140.126316-140.126316z"
                                p-id="7896"></path>
                            <path
                                d="M788.210526 350.315789H439.781053a25.6 25.6 0 1 1 0-50.930526h348.429473a25.6 25.6 0 0 1 0 50.930526zM292.109474 350.315789H233.903158a26.947368 26.947368 0 0 1 0-53.894736h58.206316a26.947368 26.947368 0 0 1 0 53.894736zM788.210526 538.947368H439.781053a25.6 25.6 0 1 1 0-50.930526h348.429473a25.6 25.6 0 0 1 0 50.930526zM292.109474 540.564211H233.903158a26.947368 26.947368 0 0 1 0-53.894737h58.206316a26.947368 26.947368 0 0 1 0 53.894737zM788.210526 745.094737H439.781053a25.6 25.6 0 1 1 0-50.930526h348.429473a25.6 25.6 0 0 1 0 50.930526zM292.109474 746.442105H233.903158a26.947368 26.947368 0 1 1 0-53.894737h58.206316a26.947368 26.947368 0 0 1 0 53.894737z"
                                p-id="7897"></path>
                        </svg>
                        <el-text style="margin-left: 5px;">
                            题目管理
                        </el-text>
                    </el-button>
                </div>
            </el-drawer>
        </div>
        <div class="administrator-right">
            <div class="right-user" v-if="!pagestate">
                <div class="right-left">
                    <div class="user-num">
                        <div>
                            <el-text type="primary" size="large">用户量</el-text>
                            <el-text size="large" style="margin-left: 5px;">{{ userNumber }}</el-text>
                        </div>
                    </div>
                    <div class="user-table">
                        <el-table style="width: 100%;border-radius: 20px;" :data="users">
                            <el-table-column label="ID" prop="id" :show-overflow-tooltip="true"
                                width="50"></el-table-column>
                            <el-table-column label="账号" prop="account" :show-overflow-tooltip="true" width="120">
                                <template #default="users">
                                    <RouterLink :to="{ path: '/user/' + users.row.account }">
                                        <el-text>{{ users.row.account }}</el-text>
                                    </RouterLink>
                                </template>
                            </el-table-column>
                            <el-table-column label="用户名" prop="username" :show-overflow-tooltip="true"
                                width="120"></el-table-column>
                            <el-table-column label="邮箱" prop="email" :show-overflow-tooltip="true"
                                width="175"></el-table-column>
                            <el-table-column label="权限" prop="authen" :show-overflow-tooltip="true"
                                width="70"></el-table-column>
                            <el-table-column label="学校" prop="school" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column fixed="right" label="操作" width="120">
                                <template #default="users">
                                    <el-button link type="primary" size="small"
                                        @click="look_user_dia(users.row)">修改权限</el-button>
                                    <el-button link type="primary" size="small"
                                        @click="banUser(users.row.id)">封号</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 20px;padding-bottom: 20px;">
                            <el-pagination :hide-on-single-page="true" layout="prev, pager, next,jumper"
                                :total="pageCurrent.total" v-model:page-size="pageCurrent.size" :small="false"
                                :background="false" :pager-count="5" v-model:current-page="userpage"></el-pagination>
                        </div>
                        <el-dialog title="修改权限" v-model="userdiabool" width="300px">
                            <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                <el-avatar :src="userdia.photo" :size="100"></el-avatar>
                                <el-text style="font-size: large;margin-top: 20px;">{{ userdia.username }}</el-text>
                                <div style="display: flex;margin-top: 20px;">
                                    <el-text style="width: 100px;">权限</el-text>
                                    <el-input v-model="userdia.authen"></el-input>
                                </div>
                                <el-button type="danger" style="margin-top: 20px;width: 100px;"
                                    @click="updateAuthen">确定</el-button>
                            </div>
                        </el-dialog>
                    </div>
                </div>
                <div class="right-right">
                    <el-card style="border-radius: 20px;">
                        <div>
                            <el-input placeholder="用户搜索" v-model="usersearch" @keydown.enter="userList"></el-input>
                        </div>
                    </el-card>
                </div>
            </div>

            <div class="right-problem" v-else>
                <div class="right-left">
                    <div class="problem-num">
                        <div>
                            <el-text type="primary" size="large">题目总数</el-text>
                            <el-text size="large" style="margin-left: 5px;">{{ pageCurrent.total }}</el-text>
                        </div>
                        <div>
                            <RouterLink :to="{ name: 'addproblem' }">
                                <el-button text type="danger">创建题目</el-button>
                            </RouterLink>
                        </div>
                    </div>
                    <div class="problem-table">
                        <el-table style="width: 100%;border-radius: 20px;" :data="problems">
                            <el-table-column label="ID" prop="id" :show-overflow-tooltip="true"
                                width="50"></el-table-column>
                            <el-table-column label="题目" prop="title" :show-overflow-tooltip="true"
                                width="150"></el-table-column>
                            <el-table-column label="通过率%" prop="pass" :show-overflow-tooltip="true"
                                width="100"></el-table-column>
                            <el-table-column label="难度" width="80">
                                <template #default="problems">
                                    <el-text
                                        :class="{ 'green': problems.row.difficulty === '简单', 'orange': problems.row.difficulty === '中等', 'red': problems.row.difficulty == '困难' }">
                                        {{ problems.row.difficulty }}
                                    </el-text>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" prop="createTime" :show-overflow-tooltip="true"
                                width="160"></el-table-column>
                            <el-table-column label="创建人" prop="username" :show-overflow-tooltip="true" width="150">
                                <template #default="problems">
                                    <RouterLink :to="{ path: '/user/' + problems.row.account }">
                                        <el-text>{{ problems.row.username }}</el-text>
                                    </RouterLink>
                                </template>
                            </el-table-column>
                            <el-table-column label="标签" prop="tags" :show-overflow-tooltip="true" width="300">
                                <template #default="problems">
                                    <div class="tag-group my-2 flex flex-wrap gap-1 items-center">
                                        <el-tag v-for="item in problems.row.tags" :key="item" class="mx-1" effect="dark"
                                            round style="margin-left: 5px;">
                                            {{ item }}
                                        </el-tag>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="220">
                                <template #default="problems">
                                    <el-button link type="primary" size="small"
                                        @click="jumpToProblem(problems.row.id)">详情</el-button>
                                    <el-button link type="primary" size="small"
                                        @click="updateProblem(problems.row.id)">修改</el-button>
                                    <el-button link type="primary" size="small"
                                        @click="problemDataIn(problems.row.id)">输入</el-button>
                                    <el-button link type="primary" size="small"
                                        @click="problemDataOut(problems.row.id)">输出</el-button>
                                    <el-button link type="primary" size="small" @click="deleteProblem(problems.row)">{{
                                        problems.row.isDelete == 1 ? '发布' : "隐藏" }}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div>
                            <el-dialog title="题目数据" v-model="dataDia" width="300">
                                <div>
                                    <input type="file" @change="ProblemDataUpload">
                                </div>
                                <div v-for="file in fileList" :key="file">
                                    <div
                                        style="display: flex;justify-content: space-between;margin: 5px;align-items: center;">
                                        <div>
                                            <el-text>{{ file }}</el-text>
                                        </div>
                                        <div>
                                            <el-button link type="primary" @click="downProblemData(file)">下载</el-button>
                                            <el-button link type="primary" @click="delProblemData(file)">删除</el-button>
                                        </div>
                                    </div>
                                </div>
                            </el-dialog>
                        </div>
                        <div style="margin-top: 20px;padding-bottom: 20px;">
                            <el-pagination :hide-on-single-page="true" layout="prev, pager, next,jumper"
                                :total="pageCurrent.total" v-model:page-size="pageCurrent.size" :small="false"
                                :background="false" :pager-count="5" v-model:current-page="problempage"></el-pagination>
                        </div>
                    </div>
                </div>
                <div class="right-right">
                    <el-card style="border-radius: 20px;">
                        <div>
                            <el-input placeholder="题目搜索" v-model="problemsearch" @keydown.enter="problemList"></el-input>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref, watch, watchEffect } from 'vue';
import store from '@/store';
import $ from 'jquery';
import { ElMessage } from 'element-plus';
import router from '@/router';

const fileList = ref([]);

const dataDia = ref(false);

const op = ref("in");

const problemId = ref(0);

const problemDataIn = (id) => {
    dataDia.value = true;

    op.value = "in";

    problemId.value = id;

    getProblemDataList("in", id);
}

const problemDataOut = (id) => {
    dataDia.value = true;

    op.value = "out";

    problemId.value = id;

    getProblemDataList("out", id);
}

const downProblemData = (filename) => {
    const downloadUrl = `https://staroj.top:3010/Problem/download/${problemId.value}/${op.value}`;
    const requestData = {
        filename: filename
    };

    // 发起 Ajax 请求
    $.ajax({
        url: downloadUrl,
        type: 'post',
        data: requestData,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        xhrFields: {
            responseType: 'blob' // 设置响应类型为二进制数据
        },
        success: function (data) {
            // 处理返回的二进制数据
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);

            // 创建一个隐藏的 a 标签并设置下载属性
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;

            // 将 a 标签添加到页面并触发点击事件
            document.body.appendChild(a);
            a.click();

            // 释放 URL 对象
            window.URL.revokeObjectURL(url);
        }
    });
};

const ProblemDataUpload = (event) => {

    // 获取上传的文件
    let file = event.target.files[0];
    let formData = new FormData();

    // 增加数据
    formData.append('file', file);

    $.ajax({
        url: "https://staroj.top:3010/Problem/upload/" + problemId.value + "/" + op.value,
        type: "post",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: formData,
        processData: false,
        contentType: false,
        success(resp) {
            if (resp.message == "success") {
                open("上传成功", "success");
                event.target.value = "";
                getProblemDataList(op.value, problemId.value)
            }
        }
    })
}

const delProblemData = (problemName) => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/del/" + problemId.value,
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: {
            op: op.value,
            filename: problemName
        },
        success(resp) {
            if (resp.message == "success") {
                open("删除成功", "success");
                getProblemDataList(op.value, problemId.value)
            }
        }
    })
}

// 获取题目数据列表
const getProblemDataList = (op, problemId) => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/dataList/" + problemId,
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: {
            op: op
        },
        success(resp) {
            fileList.value = resp;
        }
    })
}

// 打开页面栏
const drawer = ref(false)

// 页面状态
const pagestate = ref(false);

// 页码
const pageCurrent = reactive({
    total: 100,
    size: 1
})

const userpage = ref(1);

const problempage = ref(1);

// 全部用户
const users = ref()

const problems = ref();

const userdia = ref();

const userdiabool = ref(false);

const look_user_dia = (row) => {
    userdiabool.value = true;
    userdia.value = row;
}

const jumpToProblem = (id) => {
    router.push({
        name: 'ProblemId',
        params: {
            account: id
        }
    });
}

const updateProblem = (id) => {
    router.push({
        name: 'updateproblem',
        params: {
            id: id
        }
    });
}

const deleteProblem = (row) => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/delete",
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: {
            id: row.id
        },
        success(resp) {
            if (resp) {
                open("修改成功", "success");
                row.isDelete = !row.isDelete;
            } else {
                open("修改失败", "warning");
            }
        }
    })
}

// 修改权限
const updateAuthen = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/authen/update",
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: {
            UserId: userdia.value.id,
            authen: userdia.value.authen
        },
        success(resp) {
            if (resp) {
                open("修改成功", "success");
                userdiabool.value = false;
            } else {
                open("修改失败", "warning")
            }
        },
        error() {
            open("修改失败", "error");
        }
    })
}

// 封号
const banUser = (id) => {
    $.ajax({
        url: "https://staroj.top:3010/User/update/ban",
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token")
        },
        data: {
            id: id
        },
        success(resp) {
            if (resp) {
                open("封号成功", "success");
            } else {
                open("封号失败", "warning");
            }
        }
    })
}

// 用户搜索
const usersearch = ref("");

// 题目搜索
const problemsearch = ref("")

const userList = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/list",
        type: "post",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
            "Content-Type": "application/json"
        },
        data: JSON.stringify({
            search: usersearch.value,
            pageNum: userpage.value
        }),
        success(resp) {
            users.value = resp.data.records;
            pageCurrent.size = resp.data.size;
            pageCurrent.total = resp.data.total;
        }
    })
}

const userNumber = ref(0);

const userNum = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/number",
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        success(resp) {
            userNumber.value = resp;
        }
    })
}

const problemList = () => {
    let header = {
        "Content-Type": "application/json"
    };
    if (localStorage.getItem("jwt_token")) {
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt_token")
    }
    $.ajax({
        url: "https://staroj.top:3010/Problem/authen/list",
        type: "post",
        headers: header,
        data: JSON.stringify({
            keywords: problemsearch.value,
            pageNum: problempage.value
        }),
        success(resp) {
            problems.value = resp.data.records;
            pageCurrent.size = resp.data.size;
            pageCurrent.total = resp.data.total;
        },
        error() {
            open("题目请求失败", "error")
        }
    })
}

watchEffect(() => {
    if (store.state.user) {
        if ((store.state.user.authen & 1) != 0) {
            pagestate.value = false;
            userList();
            userNum();
        } else {
            pagestate.value = true;
            problemList();
        }
    }
})

watch(pagestate, () => {
    if (pagestate.value == false) {
        userList();
    } else {
        problemList();
    }
})

watch(problempage, () => {
    problemList();
})

watch(userpage, () => {
    userList();
})

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}

</script>

<style lang="less" scoped>
.green {
    color: green;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.administrator {

    height: 100vh;
    display: flex;

    position: relative;

    .administrator-left {

        height: 100%;
        z-index: 9999;

        display: flex;
        align-items: center;

        position: absolute;

        left: 0;

        .open-drawer {
            height: auto;
        }

        .drawer {

            .drawer-avatar {
                width: 100%;

                .user-photo {
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .avatar {
                        border-radius: 30%;
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        font-size: 0;
                    }
                }

                .user-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 5px;
                    font-size: large;
                }
            }
        }

        .drawer-list {
            margin-top: 20px;

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;

            .user-list {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .problem-list {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }


    }

    .administrator-right {

        padding-top: 100px;

        padding-left: 5%;

        padding-right: 5%;

        width: 100%;

        display: flex;


        .right-user {
            width: 100%;

            display: flex;

            .right-left {

                width: 70%;

                .user-num {
                    display: flex;
                }

                .user-table {
                    margin-top: 20px;
                }
            }

            .right-right {

                margin-top: 50px;
                margin-left: 1%;
                width: 29%;

            }
        }

        .right-problem {
            width: 100%;

            display: flex;

            .right-left {

                width: 70%;

                .problem-num {
                    display: flex;
                    justify-content: space-between;
                }

                .problem-table {
                    margin-top: 20px;
                }
            }

            .right-right {

                margin-top: 50px;
                margin-left: 1%;
                width: 29%;

            }
        }
    }


}

@media (max-width: 992px) {
    .administrator {

        .administrator-right {
            .right-user {
                display: flex;
                justify-content: center;

                .right-left {
                    width: 99%;
                }

                .right-right {
                    display: none;
                }
            }

            .right-problem {
                display: flex;
                justify-content: center;

                .right-left {
                    width: 99%;
                }

                .right-right {
                    display: none;
                }
            }
        }
    }
}
</style>