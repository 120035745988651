<template>
    <div class="problem">
        <div class="problem-left">
            <el-text style="font-size: medium;color: black;display: none;">推荐题单</el-text>
            <div class="problem-list" style="display: none;">
                <ProblemList class="problem-list-simpble"></ProblemList>
                <ProblemList class="problem-list-simpble"></ProblemList>
                <ProblemList class="problem-list-simpble"></ProblemList>
            </div>
            <el-divider />
            <el-text style="font-size: medium;color: black;">题库</el-text>
            <div class="problem-set">
                <div class="problem-choose">
                    <div>
                        <el-button type="info" plain class="choose-button">
                            <svg t="1702124418699" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="7227" width="20" height="20">
                                <path
                                    d="M484.130013 556.295899L27.59582 321.245044a42.54569 42.54569 0 0 1 0-78.845201L479.061393 7.348987a62.717774 62.717774 0 0 1 58.724316 0l459.606084 235.050856a42.54569 42.54569 0 0 1 0 78.845201l-452.489537 235.050855a63.94653 63.94653 0 0 1-60.772243 0z m510.70187-83.811424a43.057671 43.057671 0 0 1 0 79.357183l-452.489536 235.050855a66.557637 66.557637 0 0 1-59.236297 0L26.571857 551.688063a43.057671 43.057671 0 0 1 0-79.357183l35.224349-17.919364 422.333807 217.131492a66.916024 66.916024 0 0 0 59.236297 0l417.213989-216.61951 34.200386 17.407382z m0 230.391821a42.54569 42.54569 0 0 1 0 78.845201l-452.489536 235.050856a63.690539 63.690539 0 0 1-59.236297 0L26.571857 781.619101a42.54569 42.54569 0 0 1 0-78.845201l35.224349-18.431346 422.333807 217.643473a66.916024 66.916024 0 0 0 59.236297 0l417.213989-217.131491 34.200386 17.919364z"
                                    p-id="7228" fill="#e6e6e6"></path>
                            </svg>
                            <el-text style="margin-left: 10px;">全部题目</el-text>
                        </el-button>
                    </div>
                    <div>
                        <el-button type="info" plain class="choose-button">
                            <svg t="1702214364633" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="5570" width="20" height="20">
                                <path
                                    d="M512.099979 47.98969c22.895081 0 45.990119 9.717912 68.655249 28.883794 25.1346 21.255433 49.149441 53.988401 69.445081 94.529691 21.685341 43.360684 38.781668 94.229755 50.829079 151.187518 12.657281 59.85714 19.075902 123.553455 19.075902 189.339322s-6.418621 129.482182-19.075902 189.339322C688.961981 758.217102 671.875652 809.046181 650.190311 852.446857 629.894671 893.028138 605.879831 925.721115 580.755228 947.016539c-22.655133 19.165882-45.750171 28.883794-68.655249 28.883795s-45.990119-9.717912-68.65525-28.883795c-25.1346-21.255433-49.139443-53.988401-69.44508-94.52969-21.685341-43.360684-38.781668-94.229755-50.82908-151.187519C310.513288 641.402199 304.044678 577.705884 304.044678 511.920017s6.418621-129.482182 19.075902-189.339321c12.047412-56.987757 29.153737-107.826834 50.829079-151.187519 20.29564-40.591279 44.31048-73.274257 69.44508-94.529691 22.705122-19.155884 45.810158-28.873797 68.70524-28.873796m0-47.98969c-141.379625 0-255.945012 229.200758-255.945012 511.890024s114.615376 511.890024 255.945012 511.890023 255.945012-229.200758 255.945011-511.890023-114.615376-511.890024-255.945011-511.890024z"
                                    fill="#e6e6e6" p-id="5571"></path>
                                <path
                                    d="M512.099979 47.98969c22.895081 0 45.990119 9.717912 68.655249 28.883794 25.1346 21.255433 49.149441 53.988401 69.445081 94.529691 21.685341 43.360684 38.781668 94.229755 50.829079 151.187518 12.657281 59.85714 19.075902 123.553455 19.075902 189.339322s-6.418621 129.482182-19.075902 189.339322C688.961981 758.217102 671.875652 809.046181 650.190311 852.446857 629.894671 893.028138 605.879831 925.721115 580.755228 947.016539c-22.655133 19.165882-45.750171 28.883794-68.655249 28.883795s-45.990119-9.717912-68.65525-28.883795c-25.1346-21.255433-49.139443-53.988401-69.44508-94.52969-21.685341-43.360684-38.781668-94.229755-50.82908-151.187519C310.513288 641.402199 304.044678 577.705884 304.044678 511.920017s6.418621-129.482182 19.075902-189.339321c12.047412-56.987757 29.153737-107.826834 50.829079-151.187519 20.29564-40.591279 44.31048-73.274257 69.44508-94.529691 22.705122-19.155884 45.810158-28.873797 68.70524-28.873796m0-47.98969c-141.379625 0-255.945012 229.200758-255.945012 511.890024s114.615376 511.890024 255.945012 511.890023 255.945012-229.200758 255.945011-511.890023-114.615376-511.890024-255.945011-511.890024z"
                                    fill="#e6e6e6" p-id="5572"></path>
                                <path
                                    d="M260.624007 221.282459c104.277597 0 233.839761 40.281346 355.473628 110.506258 56.987757 32.892933 108.926598 70.294898 154.426823 111.186113 43.310695 38.921638 78.813068 79.132999 105.527328 119.614301 24.99463 37.871863 41.301127 74.98389 47.14987 107.406925 5.268868 29.203726 2.129542 54.068384-9.317998 73.894124-10.297788 17.836168-27.544082 31.923142-51.268985 41.881002-26.564293 11.147605-59.877136 16.796391-98.978735 16.796391-104.287594 0-233.859757-40.281346-355.493625-110.506258-56.987757-32.892933-108.926598-70.294898-154.426822-111.186112-43.310695-38.91164-78.80307-79.162992-105.51733-119.614302-24.99463-37.871863-41.301127-74.98389-47.149871-107.406924-5.268868-29.203726-2.129542-54.068384 9.317998-73.894125 10.297788-17.836168 27.544082-31.923142 51.268986-41.881002 26.564293-11.147605 59.887134-16.796391 99.028724-16.796391m0-47.98969c-89.790709 0-159.825662 27.194158-191.848783 82.672239C-1.889594 378.398703 139.250083 592.262756 384.097479 733.622386c133.921228 77.31339 271.091758 116.974869 379.488469 116.974868 89.790709 0 159.825662-27.194158 191.848783-82.672238 70.694812-122.473687-70.51485-336.33774-315.342251-477.69737-133.921228-77.31339-271.091758-116.974869-379.488469-116.974868z"
                                    fill="#e6e6e6" p-id="5573"></path>
                                <path
                                    d="M763.615942 221.282459c39.141591 0 72.444436 5.648786 98.978735 16.796391 23.714905 9.997852 40.991193 23.994845 51.258987 41.881002 11.447541 19.825741 14.586866 44.690399 9.317998 73.894125-5.838746 32.393041-22.145242 69.535061-47.14987 107.406924-26.704263 40.461307-62.206635 80.702662-105.51733 119.614302-45.480229 40.891215-97.439066 78.293179-154.406827 111.19611-121.633868 70.214915-251.20603 110.496261-355.483626 110.49626-39.151589 0-72.464431-5.648786-98.978735-16.796391-23.714905-9.997852-40.991193-23.994845-51.268986-41.881002-11.447541-19.825741-14.586866-44.690399-9.317998-73.894124 5.838746-32.393041 22.145242-69.535061 47.149871-107.406925 26.664271-40.481303 62.166644-80.692664 105.467341-119.614301 45.510222-40.891215 97.459062-78.303177 154.426822-111.186113 121.633868-70.224913 251.20603-110.496261 355.503622-110.506258m0-47.98969c-108.386714 0-245.587237 39.621488-379.488469 116.974869C139.250083 431.587276-1.889594 645.451329 68.755228 767.885025c31.993126 55.478081 102.048076 82.672238 191.848783 82.672238 108.386714 0 245.587237-39.621488 379.488469-116.974869C884.91988 592.262756 1026.129542 378.398703 955.444729 255.965008c-31.993126-55.478081-102.048076-82.672238-191.848783-82.672239z"
                                    fill="#e6e6e6" p-id="5574"></path>
                                <path
                                    d="M480.006874 536.104821H352.034368a23.994845 23.994845 0 0 1 0-47.989689h127.972506a23.994845 23.994845 0 0 1 0 47.989689z"
                                    fill="#e6e6e6" p-id="5575"></path>
                                <path
                                    d="M416.020621 600.091074a23.994845 23.994845 0 0 1-23.994845-23.994845V448.123723a23.994845 23.994845 0 0 1 47.989689 0v127.972506a23.994845 23.994845 0 0 1-23.994844 23.994845zM671.965632 536.104821h-95.979379a23.994845 23.994845 0 0 1 0-47.989689h95.979379a23.994845 23.994845 0 0 1 0 47.989689z"
                                    fill="#e6e6e6" p-id="5576"></path>
                            </svg>
                            <el-text style="margin-left: 10px;">算法</el-text>
                        </el-button>
                    </div>
                    <div>
                        <el-button type="info" plain class="choose-button">
                            <svg t="1702732352923" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="4960" width="30" height="30">
                                <path
                                    d="M704.387879 183.637333c21.721212 0 40.339394 18.618182 43.442424 40.339394v55.854546c58.957576 15.515152 105.50303 68.266667 105.50303 133.430303 0 71.369697-55.854545 130.327273-124.121212 136.533333-34.133333 77.575758-102.4 133.430303-186.181818 142.739394v111.709091h83.781818c15.515152 0 31.030303 12.412121 31.030303 31.030303 0 15.515152-12.412121 31.030303-31.030303 31.030303h-226.521212c-15.515152 0-31.030303-12.412121-31.030303-31.030303 0-15.515152 12.412121-31.030303 31.030303-31.030303h83.781818v-114.812121c-77.575758-12.412121-142.739394-62.060606-176.872727-133.430303l-3.10303-6.206061c-74.472727 0-133.430303-62.060606-133.430303-136.533333 0-65.163636 46.545455-121.018182 108.60606-136.533334h6.206061v-49.648484c0-24.824242 18.618182-43.442424 40.339394-43.442425h378.569697z m-173.769697 127.224243c-9.309091-18.618182-24.824242-21.721212-31.030303-3.103031v3.103031l-27.927273 55.854545-58.957576 9.309091c-18.618182 3.10303-24.824242 18.618182-12.412121 31.030303l3.10303 3.10303 43.442425 46.545455-9.309091 65.163636c-3.10303 21.721212 6.206061 31.030303 24.824242 21.721212h3.10303l52.751516-31.030303 52.751515 31.030303c18.618182 9.309091 27.927273 3.10303 27.927272-18.618181v-3.103031l-9.30909-65.163636 43.442424-46.545455c15.515152-15.515152 9.309091-27.927273-9.309091-34.133333h-3.10303l-65.163637-6.20606-24.824242-58.957576z m-248.242424 27.927272c-31.030303 9.309091-52.751515 37.236364-52.751516 74.472728 0 34.133333 21.721212 65.163636 55.854546 74.472727 0-6.206061 0-12.412121-3.10303-18.618182v-130.327273z m465.454545 6.206061v142.739394c27.927273-12.412121 46.545455-40.339394 46.545455-71.369697 0-31.030303-15.515152-55.854545-40.339394-68.266667l-6.206061-3.10303z"
                                    p-id="4961" fill="#e6e6e6"></path>
                            </svg>
                            <el-text style="margin-left: 10px;">比赛</el-text>
                        </el-button>
                    </div>
                </div>

                <div class="problem-select">

                    <div style="display: flex;align-items: center;" class="difficluty-sel">
                        <el-text style="color: black;width: 40px;">难度</el-text>
                        <el-select v-model="difficulty" class="sel-button">
                            <el-option v-for="item in difficultysel" :key="item.value" :label="item.label"
                                :value="item.value"
                                :class="{ 'green': item.value === '简单', 'orange': item.value === '中等', 'black': item.value === '全部', 'red': item.value == '困难' }" />
                        </el-select>
                    </div>

                    <div style="display: flex;align-items: center;margin-left: 10px;" class="state-sel">
                        <el-text style="color: black;width: 40px;">状态</el-text>
                        <el-select v-model="state" class="sel-button">

                            <el-option v-for="item in statesel" :key="item.value" :label="item.label" :value="item.value">
                                <span v-if="item.value == '全部'" style="display: flex;justify-content: center;">
                                    全部
                                </span>
                                <div v-else style="display: flex;align-items: center;">
                                    <span style="float: left;margin-right:7px;">
                                        <div v-if="item.value == '未开始'">
                                            <svg t="1702291315813" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" p-id="11194" width="16" height="16">
                                                <path
                                                    d="M512 73.142857A438.857143 438.857143 0 1 1 73.142857 512 438.857143 438.857143 0 0 1 512 73.142857m0-73.142857a512 512 0 1 0 512 512A512 512 0 0 0 512 0z"
                                                    p-id="11195" fill="#1296db"></path>
                                                <path d="M265.508571 475.428571h492.982858v73.142858H265.508571z"
                                                    p-id="11196" fill="#1296db"></path>
                                            </svg>
                                        </div>
                                        <div v-else-if="item.value == '尝试过'">
                                            <svg t="1702292724587" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" p-id="24271" width="16" height="16">
                                                <path
                                                    d="M910.336 186.368l-72.704-72.704L512 439.808 186.368 113.664 113.664 186.368 439.808 512l-326.144 325.632 72.704 72.704L512 584.192l325.632 326.144 72.704-72.704L584.192 512l326.144-325.632z"
                                                    fill="#d81e06" p-id="24272"></path>
                                            </svg>
                                        </div>
                                        <div v-else>
                                            <svg t="1702292210887" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" p-id="17724" width="16" height="16">
                                                <path
                                                    d="M402.326 848.721l-391.6-391.6c-14.3-14.3-14.3-37.4 0-51.7s37.4-14.3 51.7 0l339.8 339.9 559.3-559.3c14.3-14.3 37.4-14.3 51.7 0 14.3 14.3 14.3 37.4 0 51.7l-610.9 611z"
                                                    fill="#0e932e" p-id="17725"
                                                    data-spm-anchor-id="a313x.search_index.0.i11.35603a81kd4xYo" class="">
                                                </path>
                                            </svg>
                                        </div>
                                    </span>
                                    <span style="float: right;">
                                        {{ item.value }}
                                    </span>
                                </div>
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;margin-left: 10px;" class="tags-sel">
                        <el-text style="color: black;width: 40px;">标签</el-text>
                        <el-select placeholder="选择标签" multiple v-model="tag" collapse-tags-tooltip collapse-tags
                            :max-collapse-tags="1" style="width: 170px;">
                            <el-option v-for="item in tagsel" :key="item" :label="item" :value="item" />
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;margin-left: 10px;">
                        <el-input :suffix-icon="Search" placeholder="请输入关键字" v-model="search"
                            style="width: 150px;"></el-input>
                        <el-button type="primary" @click="problemList">搜索</el-button>
                    </div>

                    <div style="display: flex;align-items: center;margin-left: 10px;" @click="randPage" text>
                        <el-button circle type="success">
                            <svg t="1702293309556" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="25427" width="20" height="20">
                                <path
                                    d="M756.8 701.4c-28.6 0-56.1-3.7-82.8-10.9-46.7-12.8-90.8-37.1-131.3-72.5C500 580.7 460 529.8 424 466.9c-57.2-100-131-165.8-219.2-195.7-65.9-22.2-115.7-15.1-116.2-15-10.9 1.7-21.1-5.7-22.8-16.7-1.7-10.9 5.8-21.2 16.7-22.9 2.3-0.4 58.3-8.7 132.9 15.9 43.4 14.4 84.4 37 121.8 67.4 46.4 37.6 87.3 87 121.7 147 118.9 207.5 268.8 262.2 471.6 172.2 10.1-4.5 21.9 0.1 26.4 10.2s-0.1 21.9-10.2 26.4c-68.8 30.5-131.6 45.7-189.9 45.7z"
                                    p-id="25428" fill="#Ffffff"></path>
                                <path
                                    d="M785 809c-5.1 0-10.2-2-14.1-5.9-7.8-7.8-7.8-20.5 0-28.3l153.5-153.5c7.8-7.8 20.5-7.8 28.3 0s7.8 20.5 0 28.3L799.2 803.2c-3.9 3.9-9.1 5.8-14.2 5.8zM575.4 425.2c-6 0-12-2.7-15.9-7.9-6.7-8.8-5-21.3 3.8-28 106.7-81.3 232.1-88.2 383.4-21 10.1 4.5 14.6 16.3 10.2 26.4-4.5 10.1-16.3 14.6-26.4 10.2-138.6-61.5-247.6-56.4-342.9 16.3-3.7 2.7-8 4-12.2 4zM110.1 809c-16.8 0-26.9-1.5-27.7-1.7-10.9-1.7-18.4-12-16.7-22.9 1.7-10.9 11.9-18.3 22.8-16.7 0.8 0.1 38.3 5.4 90.8-7.6 70.6-17.5 133.5-58.1 187.1-120.5 7.2-8.4 19.8-9.4 28.2-2.2 8.4 7.2 9.4 19.8 2.2 28.2-32.7 38.2-69.4 69.4-109.1 92.8-31.9 18.8-65.9 32.7-100.8 41.1-31.5 7.6-58.3 9.5-76.8 9.5z"
                                    p-id="25429" fill="#Ffffff"></path>
                                <path
                                    d="M938.5 408.5c-5.1 0-10.2-2-14.1-5.9L770.9 249.1c-7.8-7.8-7.8-20.5 0-28.3 7.8-7.8 20.5-7.8 28.3 0l153.5 153.5c7.8 7.8 7.8 20.5 0 28.3-3.9 3.9-9.1 5.9-14.2 5.9z"
                                    p-id="25430" fill="#Ffffff"></path>
                            </svg>
                        </el-button>
                        <el-text style="color: green;width: 100px;margin-left: 10px;">随机一题</el-text>
                    </div>
                </div>
            </div>
            <div class="problem-table">
                <el-table :data="problemtable" stripe style="width: 100%;border-radius: 20px;" @cell-click="jumpToProblem">
                    <el-table-column label="状态" width="100">
                        <template #default="problemtable">
                            <!-- 通过 -->
                            <svg v-show="problemtable.row.status == '已通过'" t="1700671063307" class="icon"
                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4057"
                                width="25" height="25">
                                <path d="M852.8 261.6l2.3 1.2-2.3-1.2z" fill="#68BB8D" p-id="4058"></path>
                                <path
                                    d="M514.2 99.9c-228.5 0-413.7 185.2-413.7 413.7s185.2 413.7 413.7 413.7S927.8 742 927.8 513.5 742.5 99.9 514.2 99.9zM712 430.7L553 587l-77 75.3c-0.3 0.4-0.7 0.8-1.2 1.3-0.6 0.6-1.3 1.2-2 1.8-4.8 4.6-11.1 7.1-17.8 7.1h-1.1c-7 0-13.5-2.6-18.3-7.4-0.7-0.6-1.3-1.2-1.9-1.7-0.4-0.4-0.7-0.7-1-1.1L304.3 533.9c-10.4-10.4-9.7-28 1.5-39.2 5.7-5.7 13.3-8.9 21-8.9 7 0 13.5 2.6 18.3 7.4l109.4 109.4 58.1-56.8 159.1-156.3c4.8-4.7 11.2-7.2 18.1-7.2 7.8 0 15.5 3.3 21.2 9.1 11 11.4 11.6 29 1 39.3z"
                                    fill="#68BB8D" p-id="4059"></path>
                            </svg>
                            <!-- 未通过 -->
                            <svg v-show="problemtable.row.status == '尝试过'" t="1700671529076" class="icon"
                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7134"
                                width="25" height="25">
                                <path d="M512 512m-435.2 0a435.2 435.2 0 1 0 870.4 0 435.2 435.2 0 1 0-870.4 0Z"
                                    fill="#FE6D68" p-id="7135"></path>
                                <path
                                    d="M563.2 512l108.8-108.8c12.8-12.8 12.8-38.4 0-51.2-12.8-12.8-38.4-12.8-51.2 0L512 460.8 403.2 352c-12.8-12.8-38.4-12.8-51.2 0-12.8 12.8-12.8 38.4 0 51.2L460.8 512 352 620.8c-12.8 12.8-12.8 38.4 0 51.2 12.8 12.8 38.4 12.8 51.2 0L512 563.2l108.8 108.8c12.8 12.8 38.4 12.8 51.2 0 12.8-12.8 12.8-38.4 0-51.2L563.2 512z"
                                    fill="#FFFFFF" p-id="7136"></path>
                            </svg>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="题目" width="200" :show-overflow-tooltip="true" sortable>
                        <template #default="problemtable">
                            <el-text style="color: black;">star-{{ problemtable.row.id }}.{{ problemtable.row.title
                            }}</el-text>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pass" label="通过率" width="120" sortable>
                        <template #default="problemtable">
                            <el-text style="color: black;">{{ problemtable.row.pass }}%</el-text>
                        </template>
                    </el-table-column>
                    <el-table-column label="难度" width="80">
                        <template #default="problemtable">
                            <el-text
                                :class="{ 'green': problemtable.row.difficulty === '简单', 'orange': problemtable.row.difficulty === '中等', 'red': problemtable.row.difficulty == '困难' }">
                                {{ problemtable.row.difficulty }}
                            </el-text>
                        </template>
                    </el-table-column>
                    <el-table-column label="标签" prop="tags" :show-overflow-tooltip="true">
                        <template #default="problemtable">
                            <div class="tag-group my-2 flex flex-wrap gap-1 items-center">
                                <el-tag v-for="item in problemtable.row.tags" :key="item" class="mx-1" effect="dark" round
                                    style="margin-left: 5px;">
                                    {{ item }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="problem-page">
                <el-pagination :hide-on-single-page="pageValue" layout="prev, pager, next,jumper" :total="pageCurrent.total"
                    v-model:page-size="pageCurrent.size" :small="false" :background="false" :pager-count="5"
                    v-model:current-page="page"></el-pagination>
            </div>
        </div>
        <div class="problem-right">
            <div style="width: 95%;margin-left:5% ;">
                <el-card body-style="width:100%;">
                    <el-text style="color: black;">当前进度</el-text>
                    <div style="margin-top: 20px;display: flex;">
                        <div class="right-left">
                            <el-progress type="circle" :percentage="status" status="success" :width="100" />
                        </div>
                        <div class="right-right">
                            <div>
                                <el-text type="success">简单</el-text>
                                <el-text style="color: black;margin-left: 10px;">{{ userStatus.user_easyNum }}/{{
                                    userStatus.easyNum }}</el-text>
                            </div>
                            <div style="margin-top: 10px;">
                                <el-text type="warning">中等</el-text>
                                <el-text style="color: black;margin-left: 10px;">{{ userStatus.user_midNum }}/{{
                                    userStatus.midNum }}</el-text>
                            </div>
                            <div style="margin-top: 10px;">
                                <el-text type="danger">困难</el-text>
                                <el-text style="color: black;margin-left: 10px;">{{ userStatus.user_difNum }}/{{
                                    userStatus.difNum }}</el-text>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>

        </div>
    </div>
</template>

<script setup>
import ProblemList from './components/ProblemList.vue'
import { reactive, ref, watch } from 'vue'
import $ from 'jquery'
import { Search } from '@element-plus/icons-vue'
import router from '@/router';
import { ElMessage } from 'element-plus';
// 题目难度
const difficulty = ref("全部")

const userStatus = reactive({
    difNum: "0",
    easyNum: "0",
    midNum: "0",
    user_difNum: "0",
    user_easyNum: "0",
    user_midNum: "0"
})

const difficultysel = [
    {
        value: '全部',
        label: '全部'
    },
    {
        value: '简单',
        label: '简单'
    },
    {
        value: '中等',
        label: '中等'
    },
    {
        value: '困难',
        label: '困难'
    }
]

// 题目状态

const state = ref('全部')

const statesel = [
    {
        value: '全部',
        label: '全部',
    }, {
        value: '未开始',
        label: '未开始'
    },
    {
        value: '尝试过',
        label: '尝试过'
    },
    {
        value: '已通过',
        label: '已通过'
    },
]

// 题目标签
const tag = ref([])
const tagsel = ref()

const page = ref(1)

// 搜索
const search = ref();

const problemtable = ref([])

// 页码
const pageCurrent = reactive({
    total: 100,
    size: 1
})

const pageValue = ref(true)

const problemList = () => {
    let header = {
        "Content-Type": "application/json"
    };
    if (localStorage.getItem("jwt_token")) {
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt_token")
    }
    $.ajax({
        url: "https://staroj.top:3010/Problem/list",
        type: "post",
        headers: header,
        data: JSON.stringify({
            difficulty: difficulty.value,
            tags: tag.value,
            keywords: search.value,
            status: state.value,
            pageNum: page.value
        }),
        success(resp) {
            problemtable.value = resp.data.records;
            pageCurrent.size = resp.data.size;
            pageCurrent.total = resp.data.total;
        },
        error() {
            open("题目请求失败", "error")
        }
    })
}

problemList()

watch(difficulty, () => {

    let elDifficluty = $('.difficluty-sel .el-select .el-input__inner');

    if (difficulty.value == "全部")
        elDifficluty.css('color', 'black');
    else if (difficulty.value == "简单")
        elDifficluty.css('color', 'green');
    else if (difficulty.value == '中等')
        elDifficluty.css('color', 'orange');
    else
        elDifficluty.css('color', 'red');

    problemList();
})

watch(tag, () => {
    problemList();
})

watch(state, () => {
    problemList();
})

watch(page, () => {
    problemList();
})

const getTags = () => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/tags",
        type: "get",
        success(resp) {
            tagsel.value = resp.data;
        },
        error() {
            open("标签请求失败", "error");
        }
    })
}

getTags()

const jumpToProblem = (row) => {
    router.push({
        name: 'ProblemId',
        params: {
            account: row.id
        }
    });
}

const randPage = () => {
    // 获取 pageCurrent.total 的值
    const total = pageCurrent.total;

    // 生成一个范围在 [0, total) 的随机整数
    const randomNum = Math.floor(Math.random() * total) + 1;

    router.push({
        name: 'ProblemId',
        params: {
            account: randomNum
        }
    });
}

const status = ref(0);

const getStatus = () => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/status",
        type: "get",
        data: {
            account: localStorage.getItem("account")
        },
        success(resp) {
            userStatus.user_difNum = resp.user_difNum;
            userStatus.user_easyNum = resp.user_easyNum;
            userStatus.user_midNum = resp.user_midNum;
            userStatus.easyNum = resp.easyNum;
            userStatus.midNum = resp.midNum;
            userStatus.difNum = resp.difNum;
            const ac = parseInt(resp.user_easyNum) + parseInt(resp.user_midNum) + parseInt(resp.user_difNum);
            const all = parseInt(resp.easyNum) + parseInt(resp.midNum) + parseInt(resp.difNum);
            if (all == 0)
                status.value = 0;
            else
                status.value = parseInt(ac * 100 / all);
        },
        error() {
            open("系统错误", "error")
        }
    })
}

getStatus()

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>

<style lang="less" scoped>
.green {
    color: green !important;
}

.orange {
    color: orange !important;
}

.black {
    color: black !important;
}

.red {
    color: red !important;
}

.problem {
    padding-top: 80px;
    padding-left: 10%;
    padding-right: 10%;

    display: flex;


    .problem-left {
        width: 75%;

        .problem-list {

            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .problem-list-simpble {
                width: 230px;
                height: 120px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .problem-set {
            margin-top: 10px;

            .problem-choose {

                display: flex;

                .choose-button {
                    margin-left: 10px;
                    display: none;
                }

                .choose-button:hover,
                .choose-button:focus {

                    .el-text {
                        color: white;
                    }

                }
            }

            .problem-select {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                margin-top: 20px;

                .sel-button {
                    width: 100px;
                }
            }
        }

        .problem-table {
            margin-top: 20px;
        }

        .problem-page {
            margin-top: 20px;
        }
    }

    .problem-right {
        width: 25%;

        .right-right {
            margin-left: 20px;
        }
    }
}

// 小窗口样式
@media (max-width: 992px) {

    .problem {

        padding-left: 1%;
        padding-right: 1%;


        .problem-left {
            width: 100%;

            .problem-list {
                justify-content: center;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 100%;

                .problem-list-simpble {
                    width: 100%;
                }
            }

            .problem-set {
                .problem-choose {
                    white-space: nowrap;
                    overflow: auto;
                }

                .problem-select {
                    .difficluty-sel {
                        display: none !important;
                    }

                    .tags-sel {
                        display: none !important;
                    }

                    .state-sel {
                        display: none !important;
                    }
                }
            }

            .problem-page {
                width: 100%;
                display: flex;
                justify-content: center;

                margin-bottom: 50px;
            }
        }

        .problem-right {
            display: none;
        }
    }
}
</style>

<style>
.el-select .el-input__inner {
    color: black;
}

.el-table .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 992px) {
    .el-pagination>.is-last {
        display: none;
    }
}
</style>