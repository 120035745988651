<template>
    <article v-html="md.render(source)" class="markdown-body"></article>
</template>
  
<script>
import MarkdownIt from "markdown-it";
import MarkdownItAbbr from "markdown-it-abbr";
import MarkdownItAnchor from "markdown-it-anchor";
import MarkdownItFootnote from "markdown-it-footnote";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import MarkdownItSub from "markdown-it-sub";
import MarkdownItSup from "markdown-it-sup";
import MarkdownItTasklists from "markdown-it-task-lists";
import MarkdownItTOC from "markdown-it-toc-done-right";
import MarkdownItMathjax3 from "markdown-it-mathjax3";
import MarkdownItEmoji from 'markdown-it-emoji';

import hljs from "highlight.js/lib/core";

import 'highlight.js/styles/atom-one-light.css'

import java from 'highlight.js/lib/languages/java';
import cpp from 'highlight.js/lib/languages/cpp';
import python from 'highlight.js/lib/languages/python';
import c from 'highlight.js/lib/languages/c';


hljs.registerLanguage('java', java);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('python', python);
hljs.registerLanguage('c', c);

export default {
    props: {
        source: {
            type: String,
            default: ""
        }
    },
}
</script>



<script setup>
const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks:true
})
    .use(MarkdownItAbbr)
    .use(MarkdownItAnchor)
    .use(MarkdownItFootnote)
    .use(MarkdownItHighlightjs)
    .use(MarkdownItSub)
    .use(MarkdownItSup)
    .use(MarkdownItTasklists)
    .use(MarkdownItTOC)
    .use(MarkdownItMathjax3)
    .use(MarkdownItEmoji);

</script>

<style >
.markdown-body {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 5px;
}
</style>