import router from '@/router';
import $ from 'jquery'
export default {
    state: {
        id: "",
        account: "",
        email: "",
        authen: 0,
        photo: "",
        username: "",
        gender: 0,
        address: "",
        school: "",
        signature: "",
        token: "",
        is_login: false,
    },
    getters: {

    },
    mutations: {
        updateUser(state, user) {
            state.id = user.id;
            state.account = user.account;
            localStorage.setItem("account", user.account);
            state.email = user.email;
            state.authen = user.authen;
            state.photo = user.photo;
            state.username = user.username;
            state.gender = user.gender;
            state.address = {}
            state.address.province = user.address.province;
            state.address.city = user.address.city;
            state.school = user.school;
            state.signature = user.signature;
            state.is_login = user.is_login;
        },
        updateToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.id = '';
            state.account = '';
            state.email = '';
            state.authen = '';
            state.photo = '';
            state.username = '';
            state.gender = '';
            state.address = '';
            state.school = '';
            state.signature = '';
            state.is_login = false;
        }
    },
    actions: {
        loginEmail(context, data) {
            $.ajax({
                url: "https://staroj.top:3010/User/login/email",
                type: "post",
                data: {
                    email: data.email,
                    code: data.code,
                    key: data.key
                },
                success(resp) {
                    if (resp.message === "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        // localStorage.setItem("is_login", true);
                        // localStorage.setItem("email", data.email);
                        context.commit("updateToken", resp.token);
                        data.success(resp)
                    } else {
                        data.error(resp);
                    }
                },
                error() {
                    data.Loginerror("账号或验证码错误");
                }
            })
        },
        loginPassword(context, data) {
            $.ajax({
                url: "https://staroj.top:3010/User/login/password",
                type: "post",
                data: {
                    account: data.account,
                    password: data.password,
                    code: data.code,
                    key: data.key
                },
                success(resp) {
                    if (resp.message === "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        // localStorage.setItem("is_login", true);
                        // localStorage.setItem("email", data.email);
                        context.commit("updateToken", resp.token);
                        data.success(resp)
                    } else {
                        data.error(resp);
                    }
                },
                error() {
                    data.Loginerror("账号或密码错误");
                }
            })
        },
        getinfo(context, data) {
            $.ajax({
                url: "https://staroj.top:3010/User/info",
                type: "get",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    context.commit("updateUser", {
                        ...resp.data,
                        is_login: true,
                    });
                    data.success(resp);
                },
                error(resp) {
                    data.error(resp);
                }
            })
        },
        logout(context) {
            localStorage.removeItem("jwt_token");
            // localStorage.removeItem("is_login");
            localStorage.removeItem("account");
            // localStorage.removeItem("email");
            router.push({
                name: 'home'
            });
            context.commit("logout");
        }
    }
}