import {
  createRouter,
  createWebHistory
} from 'vue-router'
import store from '../store/index'
// import HomeView from '@/views/home/HomeView.vue'

/*
SetAccount:设置账号
SetInfo:设置个人信息
*/
import SetAccount from '@/views/setting/SetAccount.vue'
import SetInfo from '@/views/setting/SetInfo.vue'
import Analysis from '@/views/user/ProblemAnalysis.vue'
import ProblemView from '@/views/problem/ProblemHome.vue'
import UserAgreement from '@/views/Article/nowArticle/UserAgreement.vue'
import ReleasePage from '@/views/Article/nowArticle/ReleasePage.vue'
import ProblemId from '@/views/problem/components/ProblemView.vue'
import Administrator from '@/views/administrator/AdministratorView.vue'
import AddProblem from '@/views/administrator/AddProblem.vue'
const routes = [{
    path: '/',
    name: 'home',
    component: ProblemView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/user/:account',
    name: 'user',
    props: true,
    component: () => import('@/views/user/UserView.vue'),
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/user/',
    redirect: '/'
  },
  {
    path: '/set/account',
    name: 'setaccount',
    component: SetAccount,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/set/info',
    name: 'setinfo',
    component: SetInfo,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/problem',
    name: 'problem',
    component: ProblemView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/ReleasePage',
    name: 'ReleasePage',
    component: ReleasePage,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/Problem/:account',
    name: 'ProblemId',
    component: ProblemId,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/administrator',
    name: 'administrator',
    component: Administrator,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/addproblem',
    name: 'addproblem',
    component: AddProblem,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/updateproblem/:id',
    name: 'updateproblem',
    component: AddProblem,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/user/set/analysis',
    name: 'analysis',
    component: Analysis,
    meta: {
      requestAuth: false,
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && (!store.state.user.is_login || store.state.user.authen == 0)) {
    next({
      name: "home"
    });
  } else {
    next();
  }
})
export default router