<template>
    <!-- 吸顶标签 -->
    <el-affix class="navbar">

        <!-- 内容区域 -->
        <div class="navbar-content" id="navbar">

            <!-- 导航栏左侧 -->
            <div class="content-left">

                <!-- LoGo标签 -->
                <div class="navLogo">
                    <RouterLink :to="{ name: 'home' }">
                        <Logo>Star OJ</Logo>
                    </RouterLink>
                </div>

                <!-- 导航栏超链接 -->
                <div class="left-box">

                    <RouterLink class="left-item" :to="{ name: 'problem' }">
                        <div class="navItem">
                            <div class="navItem">
                                题目
                            </div>
                        </div>
                    </RouterLink>

                    <RouterLink class="left-item" :to="{ name: 'administrator' }"
                        v-show="store.state.user.is_login && store.state.user.authen != 0">
                        <div class="navItem">
                            <div class="navItem">
                                设置
                            </div>
                        </div>
                    </RouterLink>
                </div>


            </div>

            <!-- 导航栏右侧 -->
            <div class="content-right">

                <!-- 登录按钮 -->
                <el-button @click="loginvisible = true" class="login-button"
                    v-show="!store.state.user.is_login">登录/注册</el-button>

                <!-- 登录后窗口 -->
                <el-dropdown v-show="store.state.user.is_login">
                    <el-avatar :src="store.state.user.photo" class="user-avatar"
                        v-show="store.state.user.is_login"></el-avatar>
                    <!-- 下拉窗口 -->
                    <template #dropdown>
                        <el-dropdown-menu class="dropdown">
                            <!-- 用户名 -->
                            <el-dropdown-item disabled>{{ store.state.user.username }}</el-dropdown-item>
                            <!-- 个人中心 -->
                            <RouterLink :to="{ path: '/user/' + store.state.user.account }">
                                <el-dropdown-item>
                                    <el-text text type="primary">个人中心</el-text>
                                </el-dropdown-item>
                            </RouterLink>
                            <!-- 账号设置 -->
                            <RouterLink :to="{ name: 'setaccount' }">
                                <el-dropdown-item>
                                    <el-text text type="primary">账号设置</el-text>
                                </el-dropdown-item>
                            </RouterLink>
                            <!-- 退出 -->
                            <el-dropdown-item @click="logout" divided><el-text text
                                    type="danger">退出</el-text></el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>

                <!-- 设置图标 -->
                <div class="setting" @click="settingvisible = false">
                    <svg t="1689686597858" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="5653" width="32" height="32">
                        <path
                            d="M696.814933 119.466667H326.741333a68.266667 68.266667 0 0 0-59.306666 34.440533l-185.019734 324.266667a68.266667 68.266667 0 0 0 0 67.652266l185.019734 324.266667A68.266667 68.266667 0 0 0 326.741333 904.533333h370.0736a68.266667 68.266667 0 0 0 59.255467-34.372266l185.463467-324.266667a68.266667 68.266667 0 0 0 0-67.7888l-185.463467-324.266667A68.266667 68.266667 0 0 0 696.832 119.466667z m-370.0736 68.266666h370.0736l185.463467 324.266667-185.463467 324.266667H326.741333L141.704533 512l185.0368-324.266667z"
                            fill="#444444" p-id="5654"></path>
                        <path
                            d="M512 358.4a153.6 153.6 0 1 0 0 307.2 153.6 153.6 0 0 0 0-307.2z m0 68.266667a85.333333 85.333333 0 1 1 0 170.666666 85.333333 85.333333 0 0 1 0-170.666666z"
                            fill="#00B386" p-id="5655"></path>
                    </svg>
                </div>

            </div>

            <el-dropdown class="min-right">
                <div>
                    <svg t="1693660198518" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="25368" width="32" height="32">
                        <path
                            d="M416.76 419.06H95.15l30.15 30.15V127.6l-30.15 30.15h321.61l-30.15-30.15v321.61c0 15.77 13.87 30.88 30.15 30.15s30.15-13.25 30.15-30.15V127.6c0-16.3-13.81-30.15-30.15-30.15H95.15C78.85 97.45 65 111.26 65 127.6v321.61c0 16.3 13.81 30.15 30.15 30.15h321.61c15.77 0 30.88-13.87 30.15-30.15s-13.25-30.15-30.15-30.15zM416.76 866.22H95.15l30.15 30.15v-321.6l-30.15 30.15h321.61l-30.15-30.15v321.6c0 15.77 13.87 30.88 30.15 30.15s30.15-13.24 30.15-30.15v-321.6c0-16.3-13.81-30.15-30.15-30.15H95.15c-16.3 0-30.15 13.81-30.15 30.15v321.6c0 16.3 13.81 30.15 30.15 30.15h321.61c15.77 0 30.88-13.86 30.15-30.15s-13.25-30.15-30.15-30.15z"
                            fill="#0071C3" p-id="25369"></path>
                        <path
                            d="M538.87 212.35h342c15.75 0 31.53 0.33 47.28 0h0.66c15.78 0 30.88-13.87 30.15-30.15s-13.24-30.15-30.15-30.15h-342c-15.75 0-31.54-0.33-47.28 0h-0.67c-15.77 0-30.88 13.87-30.15 30.15s13.25 30.15 30.15 30.15zM538.87 424h342c15.75 0 31.53 0.33 47.28 0h0.66c15.78 0 30.88-13.87 30.15-30.16s-13.24-30.15-30.15-30.15h-342c-15.75 0-31.54-0.33-47.28 0h-0.67c-15.77 0-30.88 13.87-30.15 30.15S522 424 538.87 424zM538.87 662.22h342c15.75 0 31.53 0.33 47.28 0h0.66c15.78 0 30.88-13.87 30.15-30.16s-13.24-30.15-30.15-30.15h-342c-15.75 0-31.54-0.33-47.28 0h-0.67c-15.77 0-30.88 13.87-30.15 30.15s13.25 30.16 30.15 30.16zM538.87 875h342c15.75 0 31.53 0.33 47.28 0h0.66c15.78 0 30.88-13.87 30.15-30.15s-13.24-30.15-30.15-30.15h-342c-15.75 0-31.54-0.34-47.28 0h-0.67c-15.77 0-30.88 13.86-30.15 30.15S522 875 538.87 875z"
                            fill="#FD9A16" p-id="25370"></path>
                    </svg>
                </div>
                <!-- 下拉窗口 -->
                <template #dropdown>
                    <el-dropdown-menu class="dropdown">

                        <div v-show="!store.state.user.is_login">
                            <el-button @click="loginvisible = true" class="login-button" text>登录/注册</el-button>
                        </div>

                        <!-- 用户名 -->
                        <div v-show="store.state.user.is_login">
                            <el-dropdown-item disabled>{{ store.state.user.username }}</el-dropdown-item>
                        </div>
                        <!-- 个人中心 -->
                        <div v-show="store.state.user.is_login">
                            <RouterLink :to="{ path: '/user/' + store.state.user.account }">
                                <el-dropdown-item>
                                    <el-text text type="primary">个人中心</el-text>
                                </el-dropdown-item>
                            </RouterLink>
                        </div>
                        <!-- 题目 -->
                        <div>
                            <RouterLink :to="{ name: 'problem' }">
                                <el-dropdown-item>
                                    <el-text text type="primary">题目</el-text>
                                </el-dropdown-item>
                            </RouterLink>
                        </div>
                        <!-- 退出 -->
                        <div v-show="store.state.user.is_login">
                            <el-dropdown-item @click="logout" divided><el-text text
                                    type="danger">退出</el-text></el-dropdown-item>
                        </div>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <!-- 登录 -->
            <ModelDialog v-model="loginvisible">
                <Login @visible="LoginEvent"></Login>
            </ModelDialog>

            <!-- 设置 -->
            <ModelDialog v-model="settingvisible">
                <div style="display: flex;justify-content: center;">
                    <h1>更多功能敬请期待</h1>
                </div>
                <!-- <NavSetting></NavSetting> -->
            </ModelDialog>

        </div>

    </el-affix>
</template>



<script setup>
import { ref } from 'vue';
import Logo from './components/NavLogo.vue';
import ModelDialog from '../ModelDialog.vue';
import Login from './components/LoginDialog.vue';
import store from '@/store';
import $ from 'jquery';

// import { ElMessage } from 'element-plus';

// 刷新更新数据
const jwt_token = localStorage.getItem("jwt_token");

if (jwt_token) {
    store.commit("updateToken", jwt_token);
    store.dispatch("getinfo", {
        success() {

        },
        error() {
            logout()
        }
    })
}

// 登录，设置控制数据
const loginvisible = ref(false)
const settingvisible = ref(false)

// 子组件传递
const LoginEvent = (data) => {
    loginvisible.value = data;
}

// 退出
const logout = () => {
    store.dispatch("logout");
}

// const open = (now_message, color) => {
//     ElMessage({
//         message: now_message,
//         grouping: true,
//         type: color,
//     })
// }

$(window).on('scroll', function () {
    var myDiv = $('#navbar');
    if ($(window).scrollTop() === 0) { // 检查窗口滚动位置是否为0
        myDiv.css('background-color', 'rgba(255, 255, 255, 0)');
    } else {
        myDiv.css('background-color', 'rgba(255, 255, 255, 0.5)'); // 如果在顶部，改为黑色背景
    }
});
</script>

<style lang="less" scoped>
// 下拉导航栏
.dropdown {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar {

    // 长高占比
    width: 100%;
    height: 80px;

    position: absolute;

    top: 0;

    z-index: 999;

    .navbar-content {

        width: 100%;
        height: 80px;

        // 两侧对齐
        display: flex;
        justify-content: space-between;

        // 上下居中
        align-content: center;

        // 导航栏左侧样式
        .content-left {

            // 上下居中
            display: flex;
            align-items: center;

            // 导航栏超链接
            .left-box {

                // 上下居中
                display: flex;
                align-items: center;

                // 超链接单项
                .left-item {

                    // 左间距，字体颜色去除下划线
                    margin-left: 30px;

                    color: gray;

                    // 超链接下划线初始化
                    .navItem::after {

                        // 下滑内容
                        content: "";

                        display: block;

                        position: absolute;

                        // 下划线高度
                        height: 3px;

                        // 下划线颜色
                        background-color: #0099FF;

                        // 下划线宽度
                        width: 0;

                        // 动画时间
                        transition: width .25s ease-in-out;
                    }

                    // 鼠标放置样式

                    // 字体颜色
                    &:hover .navItem {
                        color: black;
                    }

                    // 下划线颜色
                    &:hover .navItem:after {
                        width: 30px;
                    }
                }

            }
        }

        // 导航栏右侧样式
        .content-right {

            // 上下居中
            display: flex;
            align-items: center;

            // 用户头像
            .user-avatar {
                margin-right: 20px;
            }

            // 登录按钮
            .login-button {
                // 渐变颜色
                background-image: linear-gradient(to right, #87CEEB, #B0E0E6);

                // 右间距
                margin-right: 20px;
            }

            // 设置按钮
            .setting {

                // 设置图标大小
                width: 32px;
                height: 32px;

                // 右间距
                margin-right: 20px;

                // 设置图标旋转动画
                animation: rotateAnimation 2s infinite linear;
            }

            /* 设置旋转关键帧 */
            @keyframes rotateAnimation {

                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .min-right {
            display: none;
        }
    }

}


// 小窗口样式
@media (max-width: 992px) {

    // 导航栏
    .navbar {

        // 高度
        height: 50px;

        // 内容区域
        .navbar-content {

            margin-top: 0;

            justify-content: center;
            align-items: center;

            // 隐藏
            .content-left {
                .left-box {
                    display: none;
                }
            }

            .content-right {
                display: none;
            }

            // 显示更多
            .min-right {

                // 显示
                display: block;

                // 位置信息
                position: absolute;

                // 右边边距
                right: 10px;
            }
        }
    }
}
</style>