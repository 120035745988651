<template>
  <NavBar></NavBar>
  <router-view />
</template>

<script setup>
import NavBar from '@/components/NavBar/NavigationBar.vue'
</script>

<script>
// 使用定义的 debounce 函数（在实际的 JavaScript 中可能会以其他方式实现）
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

// 获取全局的 ResizeObserver 对象
const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  // 重写 ResizeObserver 的构造函数，给回调函数添加防抖处理
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
</script>

<style>
body {
  background-color: rgb(247, 248, 249);
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  /* 滚动条的宽度 */
}

/* 设置滚动条上滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: rgb(149, 212, 234);
  /* 滑块的背景颜色 */
}

/* 设置滚动条上轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 轨道的背景颜色 */
}

a {
  text-decoration: none;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "zhanku";
  src: url("./font/zhanku.eot");
  /* IE9 */
  src: url("./font/zhanku.eot") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./font/zhanku.woff") format("woff"),
    /* chrome、firefox */
    url("./font/zhanku.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
    url("./font/zhanku.svg") format("svg");
  /* iOS 4.1- */
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
</style>
