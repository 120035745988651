<template>
    <!-- 利用Visible与display实现弹窗的显示和隐藏 -->
    <div class="dialog" :class="{ 'is-show': visible }" :style="display ? 'display:none' : ''">

        <!-- 不透明遮罩 -->
        <div class="dialogModal" @click.self="closeDialog"></div>

        <div class="dialogMain">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
export default defineComponent({

    // 父组件向子组件传递信息
    // modelValue和v-model一起使用
    props: {
        modelValue: Boolean
    },

    //子组件更新父组件信息
    emits: ['update:modelValue'],

    // ctx可访问上下文
    setup(props, ctx) {

        const visible = ref(props.modelValue)
        const display = ref(true)

        // 时间
        let timer = 0

        // 关闭弹窗
        const closeDialog = () => {
            clearTimeout(timer)
            visible.value = false
            timer = setTimeout(() => {
                display.value = false
                clearTimeout(timer)
            }, 300)
        }

        // currentValue接受父组件变化值，根据变化值执行相应的程序
        watch(() => props.modelValue, (currentValue) => {
            if (currentValue) {
                clearTimeout(timer)
                display.value = false
                timer = setTimeout(() => {
                    visible.value = currentValue
                    clearTimeout(timer)
                }, 20)
            } else {
                closeDialog()
            }
        })

        // 关闭向父组件更新
        watch(() => visible.value, (currentValue) => {
            if (!currentValue) {
                ctx.emit('update:modelValue', currentValue)
            }
        })

        // 生命周期钩子，防止内存外泄
        onBeforeUnmount(() => {
            clearTimeout(timer)
        })

        return {
            visible,
            display,
            closeDialog
        }
    }
})
</script>

<style lang="less" scoped>
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    &.is-show {
        visibility: visible;
        opacity: 1;

        .dialogMain {
            transform: translateY(0);
        }
    }

    .dialogModal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .dialogMain {
        transition: 0.3s;
        min-width: 450px;
        min-height: 100px;
        background-color: #fff;
        position: relative;
        border-radius: 8px;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        transform: translateY(10%);
    }
}
</style>
