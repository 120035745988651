<template>
    <div class="add-problem">
        <div style=" position: absolute;display: flex;align-items: center;height: 100%;left: 0;">
            <el-button circle type="success" class="open-drawer" @click="drawer = true" style="width: 32px;height: 32px;">
                <svg t="1703312654342" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="5155" width="32" height="32">
                    <path
                        d="M170.666667 392.533333L349.866667 213.333333l29.866666 29.866667-149.333333 149.333333h669.866667v42.666667H128l42.666667-42.666667z m682.666666 213.333334l-179.2 179.2-29.866666-29.866667 149.333333-149.333333H132.266667v-42.666667H896l-42.666667 42.666667z"
                        fill="#e6e6e6" p-id="5156"></path>
                </svg>
            </el-button>
            <el-drawer v-model="drawer" title="设置" direction="ltr" class="drawer" :size="200">
                <div class="drawer-avatar">
                    <div class="user-photo">
                        <div :style="'background-image:url(' + store.state.user.photo + ')'" class="avatar"></div>
                    </div>
                    <div class="user-name">
                        <el-text>{{ store.state.user.username }}</el-text>
                    </div>
                </div>
                <div class="drawer-list">
                    <div class="list-first" @click="state = 0">
                        <el-button text>基础设置</el-button>
                    </div>
                    <div class="list-second" @click="state = 1">
                        <el-button text>题目内容</el-button>
                    </div>
                    <div class="list-third" @click="state = 2">
                        <el-button text>题解内容</el-button>
                    </div>
                    <div class="list-fourth" @click="state = 3">
                        <el-button text>题目样例</el-button>
                    </div>
                    <div class="list-button">
                        <el-button text type="danger" @click="problemOp">{{ problem_id ? '修改题目' : '提交题目' }}</el-button>
                    </div>
                </div>
            </el-drawer>
        </div>
        <div class="add-first card" v-if="state == 0">
            <el-card
                body-style="width:500px;display: flex;align-items: center;justify-content: center;flex-direction: column;">

                <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                    <el-text style="font-size: larger;color: black;">基础设置</el-text>
                </div>
                <div class="add-title">
                    <text style="width: 50px;">标题 </text>
                    <el-input v-model="problem.title" style="width: 200px;">
                    </el-input>
                </div>

                <div class="add-tags">
                    <text style="width: 50px;margin: 10px;">标签</text>
                    <el-tag v-for="tag in problem.tags" :key="tag" class="mx-1" closable :disable-transitions="false"
                        @close="handleClose(tag)" style="margin-left: 10px;margin: 10px;">
                        {{ tag }}
                    </el-tag>
                    <el-input v-if="inputVisible" ref="InputRef" v-model="inputValue" class="ml-1 w-20"
                        @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" style="margin: 10px;" />
                    <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput"
                        style="margin-left: 10px;">
                        + 增加标签
                    </el-button>
                </div>

                <div class="add-difficulty">
                    <text style="width: 50px;">难度</text>
                    <el-select v-model="problem.difficulty" class="sel-button" style="width: 200px;">
                        <el-option v-for="item in difficultysel" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </div>

                <div style="display: flex;align-items: center;margin-top: 20px;">
                    <text style="width: 100px;">时间限制</text>
                    <el-input v-model="problem.judgeConfig.timeLimit" placeholder="请输入时间限制" style="width: 200px;">
                        <template #append>ms</template>
                    </el-input>
                </div>

                <div style="display: flex;align-items: center;margin-top: 20px;">
                    <text style="width: 100px;">内存限制</text>
                    <el-input v-model="problem.judgeConfig.memoryLimit" placeholder="请输入内存限制" style="width: 200px;">
                        <template #append>b</template>
                    </el-input>
                </div>

                <div style="margin-top: 50px;">
                    <el-button @click="state = 1" style="width: 100px;" type="danger">下一步</el-button>
                </div>
            </el-card>
        </div>

        <div class="add-second card" v-else-if="state == 1">
            <v-md-editor v-model="problem.content" class="markdown" @upload-image="handleUploadImageContent"
                :disabled-menus="[]"></v-md-editor>
        </div>

        <div class="add-third card" v-else-if="state == 2">
            <v-md-editor v-model="problem.answer" class="markdown" @upload-image="handleUploadImageAnswer"
                :disabled-menus="[]"></v-md-editor>
        </div>

        <div class="add-fourth card" v-else>
            <div class="problem-judge">
                <div>
                    <el-table :data="problem.judgeCase" style="border-radius: 10px;height:500px;" stripe
                        header-align="center">
                        <el-table-column prop="input" label="输入" align="center">
                            <template #default="scope">
                                <el-input v-model="scope.row.input" type="textarea" placeholder="请输入输入样例"
                                    :autosize="{ minRows: 4, maxRows: 4 }"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="output" label="输出" align="center">
                            <template #default="scope">
                                <el-input v-model="scope.row.output" type="textarea" placeholder="请输入输出样例"
                                    :autosize="{ minRows: 4, maxRows: 4 }"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="100">
                            <template #default="scope">
                                <el-button @click.prevent="deleteRow(scope.$index)" type="danger" text
                                    style="width: 100px;">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="display: flex;justify-content: center;">
                    <el-button style="margin-top: 10px;width: 100px;" @click="addjudge" type="success"
                        round>增加样例</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import store from '@/store';
import { reactive, ref, nextTick } from 'vue'
import $ from 'jquery'
import { ElMessage } from 'element-plus';
import router from '@/router';
const drawer = ref(false);
const problem = reactive({
    title: "",
    tags: [],
    difficulty: "简单",
    content: "# 请输入题目内容",
    answer: "# 请输入题目答案",
    judgeCase: [],
    judgeConfig: {
        timeLimit: 1000,
        memoryLimit: 256000000,
    }
})

const problem_id = router.currentRoute.value.params.id;

if (problem_id) {
    const getProblemContent = () => {
        $.ajax({
            url: "https://staroj.top:3010/Problem/" + problem_id,
            type: "get",
            success(resp) {
                problem.content = resp.data.content;
                if (resp.data.answer == null)
                    problem.answer = "";
                else
                    problem.answer = resp.data.answer;
                problem.judgeCase = resp.data.judgeCase;
                problem.judgeConfig.memoryLimit = parseInt(resp.data.judgeConfig.memoryLimit);
                problem.judgeConfig.timeLimit = parseInt(resp.data.judgeConfig.timeLimit);
                problem.title = resp.data.title;
            },
            error() {
                open("系统错误", "error")
            }
        })
    }

    getProblemContent()
}

const state = ref(0);

const inputValue = ref('')
const inputVisible = ref(false)
const InputRef = ref()

const handleClose = (tag) => {
    problem.tags.splice(problem.tags.indexOf(tag), 1)
}

const showInput = () => {
    inputVisible.value = true
    nextTick(() => {
        InputRef.value.input.focus()
    })
}

const handleInputConfirm = () => {
    if (inputValue.value) {
        problem.tags.push(inputValue.value)
    }
    inputVisible.value = false
    inputValue.value = ''
}


const difficultysel = [
    {
        value: "简单",
        label: "简单",
    },
    {
        value: "中等",
        label: "中等",
    },
    {
        value: "困难",
        label: "困难"
    }
]

// 添加样例
const addjudge = () => {
    problem.judgeCase.push({
        input: "",
        output: ""
    })
}

// 删除样例
const deleteRow = (index) => {
    problem.judgeCase.splice(index, 1)
}

const handleUploadImageContent = (event, insertImage, files) => {
    let file = files[0]
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", files.type);
    $.ajax({
        url: "https://img.staroj.top:3001/upload",
        type: "post",
        data: formData,
        processData: false,
        contentType: false,
        success(resp) {
            if (resp.message == "success") {
                // 此处只做示例
                insertImage({
                    url:
                        'https://img.staroj.top:3001/' + resp.img,
                    desc: resp.img,
                });
            }
        },
        error() {
            open("上传失败", "error")
        }
    })
}

const handleUploadImageAnswer = (event, insertImage, files) => {
    let file = files[0]
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", files.type);
    $.ajax({
        url: "https://img.staroj.top:3001/upload",
        type: "post",
        data: formData,
        processData: false,
        contentType: false,
        success(resp) {
            if (resp.message == "success") {
                // 此处只做示例
                insertImage({
                    url:
                        'https://img.staroj.top:3001/' + resp.img,
                    desc: resp.img,
                });
            }
        },
        error() {
            open("上传失败", "error")
        }
    })
}

const problemOp = () => {
    if (problem_id) {
        updateProblem()
    } else {
        addProblem()
    }
}

const updateProblem = () => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/update",
        type: "post",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
            "Content-Type": "application/json"
        },
        data: JSON.stringify({
            id: problem_id,
            title: problem.title,
            content: problem.content,
            difficulty: problem.difficulty,
            tags: problem.tags,
            answer: problem.answer,
            judgeCase: problem.judgeCase,
            judgeConfig: problem.judgeConfig
        }),
        success(resp) {
            if (resp.message == "success") {
                open("修改成功", "success");
                jumpToProblem(problem_id);
            }
        },
        error() {
            console.log("上传失败");
        }
    })
}

const addProblem = () => {
    $.ajax({
        url: "https://staroj.top:3010/Problem/add",
        type: "post",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
            "Content-Type": "application/json"
        },
        data: JSON.stringify({
            title: problem.title,
            content: problem.content,
            difficulty: problem.difficulty,
            tags: problem.tags,
            answer: problem.answer,
            judgeCase: problem.judgeCase,
            judgeConfig: problem.judgeConfig
        }),
        success(resp) {
            if (resp.message == "success") {
                open("添加成功", "success");
                jumpToProblem(resp.id);
            }
        },
        error() {
            console.log("上传失败");
        }
    })
}

const jumpToProblem = (id) => {
    router.push({
        name: 'ProblemId',
        params: {
            account: id
        }
    });
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>

<style lang="less" scoped>
.add-problem {

    position: relative;

    height: 100vh;

    display: flex;


    .open-drawer {
        height: auto;
    }

    .drawer {
        .drawer-avatar {
            width: 100%;

            .user-photo {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: center;

                .avatar {
                    border-radius: 30%;
                    width: 100px;
                    height: 100px;
                    background-size: cover;
                    font-size: 0;
                }
            }

            .user-name {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                font-size: large;
            }
        }

        .drawer-list {
            margin-top: 20px;

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;

            .list-first {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .list-second {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }

            .list-third {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }

            .list-fourth {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }

            .list-button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
            }
        }
    }

    .card {
        padding-top: 100px;
        width: 100%;

        display: flex;
        justify-content: center;
    }


    .add-first {

        display: flex;
        align-items: center;

        .add-title {
            margin-top: 20px;
            display: flex;
            align-items: center;
        }

        .add-tags {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .add-difficulty {
            margin-top: 20px;
            display: flex;
            align-items: center;
        }
    }

    .add-second {

        padding-left: 10%;
        padding-right: 10%;

        .markdown {
            z-index: 9999 !important;
        }
    }

    .add-third {
        padding-left: 10%;
        padding-right: 10%;

        .markdown {
            z-index: 9999 !important;
        }
    }

    .add-fourth {
        padding-left: 10%;
        padding-right: 10%;

        .problem-judge {
            height: 100%;
            width: 100%;
        }
    }

}
</style>
