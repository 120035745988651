<template>
    <div class="flex-container">
        <div class="flex-item copy">
            <text>输入</text>
            <div style="margin-left: 5px;" @click="copy(sample.input)">
                <svg t="1700219547950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="4065" width="16" height="16">
                    <path
                        d="M817.322667 704H704v-113.322667a35.968 35.968 0 1 0-72.021333 0V704h-105.301334a35.968 35.968 0 1 0 0 72.021333h105.344v105.301334a35.968 35.968 0 1 0 71.978667 0v-105.301334h113.322667a35.968 35.968 0 1 0 0-72.021333z m57.770666-316.885333L597.12 106.666667a77.44 77.44 0 0 0-14.805333-11.52 60.928 60.928 0 0 0-4.394667-2.261334c-0.938667-0.512-1.92-0.896-2.816-1.28A72.362667 72.362667 0 0 0 546.005333 85.333333h-346.026666C160.256 85.333333 128 117.546667 128 157.354667v709.333333C128 906.410667 160.213333 938.666667 200.021333 938.666667h211.968a35.968 35.968 0 1 0 0-72.021334H199.978667V157.354667H512v240.042666c0 39.765333 32.213333 71.978667 72.021333 71.978667h239.957334v155.989333a35.968 35.968 0 1 0 72.021333 0v-187.477333c0-19.029333-7.509333-37.205333-20.906667-50.730667z m-291.114666 10.24V195.712l199.808 201.642667h-199.808z"
                        fill="#595959" p-id="4066"></path>
                </svg>
            </div>

        </div>
        <div class="flex-item copy">
            <text>输出</text>
            <div style="margin-left: 5px;" @click="copy(sample.output)">
                <svg t="1700219547950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="4065" width="16" height="16">
                    <path
                        d="M817.322667 704H704v-113.322667a35.968 35.968 0 1 0-72.021333 0V704h-105.301334a35.968 35.968 0 1 0 0 72.021333h105.344v105.301334a35.968 35.968 0 1 0 71.978667 0v-105.301334h113.322667a35.968 35.968 0 1 0 0-72.021333z m57.770666-316.885333L597.12 106.666667a77.44 77.44 0 0 0-14.805333-11.52 60.928 60.928 0 0 0-4.394667-2.261334c-0.938667-0.512-1.92-0.896-2.816-1.28A72.362667 72.362667 0 0 0 546.005333 85.333333h-346.026666C160.256 85.333333 128 117.546667 128 157.354667v709.333333C128 906.410667 160.213333 938.666667 200.021333 938.666667h211.968a35.968 35.968 0 1 0 0-72.021334H199.978667V157.354667H512v240.042666c0 39.765333 32.213333 71.978667 72.021333 71.978667h239.957334v155.989333a35.968 35.968 0 1 0 72.021333 0v-187.477333c0-19.029333-7.509333-37.205333-20.906667-50.730667z m-291.114666 10.24V195.712l199.808 201.642667h-199.808z"
                        fill="#595959" p-id="4066"></path>
                </svg>
            </div>
        </div>
        <div class="flex-item">
            <el-text style="white-space: pre-line">{{ sample.input }}</el-text>
        </div>
        <div class="flex-item">
            <el-text style="white-space: pre-line">{{ sample.output }}</el-text>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sample: {
            default: ""
        }
    },
}
</script>

<script setup>
import { ElMessage } from 'element-plus';

const copy = (data) => {
    navigator.clipboard.writeText(data).then(() => {
        open("复制成功~", "success")
    })
}
const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>

<style>
.flex-container {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
}

.flex-item {
    display: flex;
    flex-basis: 50%;
    border: 0.1px solid rgba(144, 142, 142, 0.7);
}
</style>