<template>
    <div class="set">
        <div class="set-left">
            <div class="user-photo">
                <div :style="'background-image:url(' + store.state.user.photo + ')'" class="avatar"></div>
            </div>
            <div class="user-name">
                <el-text>{{ store.state.user.username }}</el-text>
            </div>
            <el-divider />
            <div class="button-list">
                <RouterLink :to="{ name: 'setaccount' }">
                    <div class="account-button">
                        <el-button text>
                            <svg t="1701494329341" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="4251" width="25" height="25">
                                <path
                                    d="M867.584 160.192c-149.632-16.928-262.208-57.408-334.592-120.352l-19.04-16.544-20.544 14.656C379.968 118.944 267.776 160 160 160H128v448c0 137.344 121.088 261.92 370.208 380.864l13.088 6.24 13.344-5.728C771.072 883.52 896 755.232 896 608V163.424l-28.416-3.232zM832 608c0 116.8-107.392 223.36-319.328 316.8C299.872 821.024 192 714.464 192 608V222.976c104.672-6.784 211.584-46.688 318.496-118.944C587.232 162.528 695.168 201.536 832 220.256V608z"
                                    p-id="4252" fill="#8a8a8a"></path>
                                <path
                                    d="M359.776 468.672a32 32 0 1 0-47.968 42.4l121.792 137.824c12.608 14.24 30.176 21.568 47.904 21.568a64.384 64.384 0 0 0 49.696-23.52l197.6-242.72a32 32 0 0 0-49.632-40.416l-197.6 242.688-121.792-137.824z"
                                    p-id="4253" fill="#8a8a8a"></path>
                            </svg>
                            <el-text style="margin-left: 15px;">
                                账号安全
                            </el-text>
                        </el-button>
                    </div>
                </RouterLink>
                <RouterLink :to="{ name: 'setinfo' }">
                    <div class="info-button">
                        <el-button text>
                            <svg t="1701494854412" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="5373" width="25" height="25">
                                <path
                                    d="M918.84347 765.702891h-255.234298c-14.293121 0-25.52343-11.230309-25.523429-25.523429s11.230309-25.52343 25.523429-25.52343h255.234298c13.782652 0 25.52343-8.677966 25.523429-19.397807V70.444666c0-10.71984-11.740778-19.397807-25.523429-19.397807H408.374875c-13.782652 0-25.52343 8.677966-25.523429 19.397807v169.986042c0 14.293121-11.230309 25.52343-25.52343 25.52343s-25.52343-11.230309-25.52343-25.52343V70.444666C331.804586 31.649053 366.005982 0 408.374875 0h510.468595c42.368893 0 76.570289 31.649053 76.570289 70.444666v625.324028c0 38.285145-34.201396 69.934197-76.570289 69.934197z"
                                    fill="#8a8a8a" p-id="5374"></path>
                                <path
                                    d="M852.993021 255.234297H485.966102c-14.293121 0-25.52343-11.230309-25.52343-25.52343s11.230309-25.52343 25.52343-25.523429h367.026919c14.293121 0 25.52343 11.230309 25.52343 25.523429s-11.230309 25.52343-25.52343 25.52343z"
                                    fill="#8a8a8a" p-id="5375"></path>
                                <path
                                    d="M852.993021 408.374875H587.038883c-14.293121 0-25.52343-11.230309-25.523429-25.523429s11.230309-25.52343 25.523429-25.52343h265.954138c14.293121 0 25.52343 11.230309 25.52343 25.52343s-11.230309 25.52343-25.52343 25.523429z"
                                    fill="#8a8a8a" p-id="5376"></path>
                                <path
                                    d="M852.993021 561.515454h-183.258225c-14.293121 0-25.52343-11.230309-25.52343-25.52343s11.230309-25.52343 25.52343-25.52343h183.258225c14.293121 0 25.52343 11.230309 25.52343 25.52343s-11.230309 25.52343-25.52343 25.52343z"
                                    fill="#8a8a8a" p-id="5377"></path>
                                <path
                                    d="M331.804586 740.179462c-112.813559 0-204.187438-91.373878-204.187437-204.187438s91.373878-204.187438 204.187437-204.187438 204.187438 91.373878 204.187438 204.187438-91.373878 204.187438-204.187438 204.187438z m0-357.328016c-84.227318 0-153.140578 68.91326-153.140578 153.140578s68.91326 153.140578 153.140578 153.140578 153.140578-68.91326 153.140579-153.140578-68.91326-153.140578-153.140579-153.140578z"
                                    fill="#8a8a8a" p-id="5378"></path>
                                <path
                                    d="M638.085743 1024l-27.565304-3.062812c-23.481555-1.020937-477.798604-1.020937-558.963111 0l-26.033898 0.510469v-26.033898c0-168.965105 137.316052-306.281157 306.281156-306.281157s306.281157 137.316052 306.281157 306.281157v28.586241z m-306.281157-283.820538c-132.211366 0-240.941176 100.562313-254.21336 229.710867 100.051844-0.510469 409.906281-1.020937 507.916252 0a254.928016 254.928016 0 0 0-253.702892-229.710867z"
                                    fill="#8a8a8a" p-id="5379"></path>
                            </svg>
                            <el-text style="margin-left: 15px;">
                                个人资料
                            </el-text>
                        </el-button>
                    </div>
                </RouterLink>
                <el-divider />
                <div class="problem-button">
                    <el-button text>
                        <svg t="1701495914011" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="9485" width="25" height="25">
                            <path
                                d="M512 127.168l-62.912 1.6c-216.128 15.84-387.2 195.84-387.2 416.032 0 230.624 186.656 417.568 417.312 417.568 220.224 0 400-171.456 415.936-387.584L896.512 512 512 512 512 127.168zM833.024 576c-15.648 192-168.096 324.672-354.112 324.672-196.448 0-355.744-159.36-355.744-355.808C123.168 358.848 256 206.304 448 190.624L448 576 833.024 576zM544 65.344 544 480l414.368 0C958.368 256 768 65.344 544 65.344zM608 131.872c32 4.576 49.44 12.192 74.4 22.752 42.432 17.92 79.04 43.616 111.776 76.352 32.736 32.736 57.696 68.032 75.648 110.464 10.56 24.96 17.408 42.56 22.016 74.56L608 416 608 131.872z"
                                fill="#8a8a8a" p-id="9486"></path>
                        </svg>
                        <el-text style="margin-left: 15px;">
                            做题分析
                        </el-text>
                    </el-button>
                </div>
                <div class="code-button">
                    <el-button text>
                        <svg t="1701858990362" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4581" width="25" height="25">
                            <path
                                d="M832 128H192c-35.328 0-64 28.672-64 64v640c0 35.328 28.672 64 64 64h640c35.328 0 64-28.672 64-64V192c0-35.328-28.672-64-64-64zM395.776 626.688l-45.056 45.056-158.208-158.208 45.056-45.056L350.72 355.328l45.056 45.056-113.152 113.152 113.152 113.152zM480.256 768h-64l128-512h64l-128 512z m193.536-96.256l-45.056-45.056 113.152-113.152-113.152-113.152 45.056-45.056L786.944 468.48 832 513.536 673.792 671.744z"
                                fill="#8a8a8a" p-id="4582"></path>
                        </svg>
                        <el-text style="margin-left: 15px;">
                            代码模板
                        </el-text>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="set-right">
            <div class="set-content">
                <el-card class="content-card">
                    <div class="info-avatar">
                        <input type="file" accept="image/*" class="avatar-input" @change="update($event, 'avatar')"
                            :style="'background-image:url(' + user.photo + ')'">
                    </div>
                    <div class="info-user">
                        <div class="info-name">

                            <div>
                                <el-text>
                                    昵称
                                </el-text>
                            </div>

                            <el-input style="margin-top: 5px;" v-model="user.username"></el-input>
                        </div>

                        <div class="info-gender">
                            <div>
                                <el-text>
                                    性别
                                </el-text>
                            </div>
                            <div class="gender-button">
                                <el-button
                                    :style="user.gender == 0 ? 'background-color: rgb(235,245,255);' : 'background-color: rgb(247,247,247)'"
                                    @click="user.gender = 0">
                                    <svg t="1701859681585" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" p-id="5922" width="25" height="25">
                                        <path
                                            d="M542.117647 187.733333a15.058824 15.058824 0 0 0 0 30.117647h273.066667l-195.96549 195.965491a279.491765 279.491765 0 1 0 21.283137 21.283137l195.96549-195.96549v273.066666a15.058824 15.058824 0 1 0 30.117647 0V187.733333z m-108.222745 680.658824A247.968627 247.968627 0 1 1 682.666667 620.423529a248.169412 248.169412 0 0 1-247.968628 247.968628z"
                                            :fill="user.gender == 0 ? '#00BFFF' : '#A9A9A9'" p-id="5923"></path>
                                    </svg>
                                    <el-text style="margin-left: 5px;"
                                        :style="user.gender == 0 ? 'color: rgb(0,191,255);' : 'color: gray;'">
                                        男
                                    </el-text>
                                </el-button>
                                <el-button
                                    :style="user.gender == 1 ? 'background-color: rgb(255,238,249);' : 'background-color: rgb(247,247,247)'"
                                    @click="user.gender = 1">
                                    <svg t="1701859736156" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" p-id="7043" width="25" height="25">
                                        <path
                                            d="M582.27451 164.442353A278.487843 278.487843 0 0 0 303.385098 441.72549a275.676863 275.676863 0 0 0 71.479216 185.323922l-92.16 92.16-92.561569-91.758432a15.058824 15.058824 0 0 0-21.283137 21.283138L261.019608 741.295686l-92.16 92.561569a15.058824 15.058824 0 0 0 0 21.283137 15.861961 15.861961 0 0 0 10.641568 4.417255 15.460392 15.460392 0 0 0 10.641569-4.417255L282.704314 762.980392l92.561568 92.561569a15.460392 15.460392 0 0 0 10.641569 4.417255 15.861961 15.861961 0 0 0 10.641569-4.417255 15.058824 15.058824 0 0 0 0-21.283137l-92.561569-92.561569 92.16-92.16A275.676863 275.676863 0 0 0 582.27451 720.614902a278.086275 278.086275 0 1 0 0-556.172549z m0 526.054902A247.968627 247.968627 0 1 1 829.44 441.72549 248.169412 248.169412 0 0 1 582.27451 690.497255z"
                                            :fill="user.gender == 1 ? '#FF69B4' : '#A9A9A9'" p-id="7044"></path>
                                    </svg>
                                    <el-text style="margin-left: 5px;"
                                        :style="user.gender == 1 ? 'color: rgb(255,105,180);' : 'color: gray;'">
                                        女
                                    </el-text>
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="user-address">
                        <div class="user-live">
                            <div>
                                <el-text>
                                    现居地
                                </el-text>
                            </div>
                            <div class="select">
                                <el-select v-model="userprovince" style="width: 49%;">
                                    <el-option v-for="item in province" :key="item.province" :label="item.name"
                                        :value="item.province" />
                                </el-select>
                                <el-select v-model="usercity" style="width: 49%;margin-left: 2%;">
                                    <el-option v-for="item in choosecity" :key="item.code" :label="item.name"
                                        :value="item.code" />
                                </el-select>
                            </div>

                        </div>

                        <div class="user-shool">
                            <div>
                                <el-text>
                                    学校
                                </el-text>
                            </div>
                            <el-input style="margin-top: 5px;" v-model="user.school"></el-input>
                        </div>
                    </div>

                    <div class="user-word">
                        <div>
                            <el-text>个性签名</el-text>
                        </div>
                        <el-input :autosize="{ minRows: 4, maxRows: 4 }" type="textarea" placeholder="请输入个性签名~"
                            show-word-limit maxlength="32" style="margin-top: 5px;" resize="none"
                            v-model="user.signature" />
                    </div>

                    <div class="save-button">
                        <el-button type="success" @click="updateInfo">保存</el-button>
                    </div>

                </el-card>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, watch, watchEffect } from 'vue'
import store from '@/store';
import $ from 'jquery';
import { ElMessage } from 'element-plus';
const { province, city } = require('province-city-china/data');

// 选择的市
const choosecity = ref([]);

const updateInfo = () => {
    $.ajax({
        url: "https://staroj.top:3010/User/update/info",
        type: "post",
        headers: {
            Authorization: "Bearer " + store.state.user.token,
            "Content-Type": "application/json"
        },
        data: JSON.stringify({
            username: user.username,
            gender: user.gender,
            address: {
                province: userprovince.value,
                city: usercity.value
            },
            school: user.school,
            signature: user.signature
        }),
        success(resp) {
            if (resp.message == "success") {
                open("修改成功", "success");
                store.dispatch("getinfo", {
                    success() {
                    },
                    error() {
                    }
                })
            } else {
                open(resp.message, "warning");
            }
        },
        error() {
            open("修改失败", "error");
        }
    })
}

const update = (resp, type) => {

    // 获取上传的文件
    let file = resp.target.files[0];
    let formData = new FormData();

    // 增加数据
    formData.append('file', file);
    formData.append('type', type);
    $.ajax({
        url: "https://img.staroj.top:3001/upload",
        type: "post",
        data: formData,
        processData: false,
        contentType: false,
        // 发送成功
        success(resp) {
            if (resp.message == "success") {
                updateimg(resp.img)
            }
        },
        // 发送失败
        error() {
            open("修改取消~", "info");
        }
    })
}

const updateimg = (img) => {
    $.ajax({
        url: "https://staroj.top:3010/User/update/avatar",
        type: "get",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: {
            url: "https://img.staroj.top:3001/" + img
        },
        success(resp) {
            if (resp.message == "success") {
                open("修改成功", "success")
                store.dispatch("getinfo", {
                    success() {
                    },
                    error() {
                    }
                })
            }
        },
        error() {
            open("修改失败", "error");
        }
    })
}

const user = reactive({
    username: "",
    photo: "",
    gender: "",
    signature: "",
    school: "",
})


const userprovince = ref("");
const usercity = ref("");

// 挑选省市
watch(userprovince, () => {

    // 初始化
    choosecity.value = [];

    city.forEach((v) => {
        if (v.province == userprovince.value)
            choosecity.value.push(v);
    });

    if (choosecity.value.length == 0) {
        usercity.value = "";
    } else {
        usercity.value = choosecity.value[0].code;
    }
})


watchEffect(() => {
    if (store.state.user) {
        user.username = store.state.user.username;
        user.photo = store.state.user.photo;
        user.gender = store.state.user.gender;
        user.signature = store.state.user.signature;
        user.school = store.state.user.school;
        province.forEach((v) => {
            if (v.province == store.state.user.address.province)
                userprovince.value = v.province;
        });
        city.forEach((v) => {
            if (v.code == store.state.user.address.city) {
                usercity.value = v.code
            }
        })
    }
})

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>

<style lang="less" scoped>
.set {
    padding-top: 100px;
    padding-left: 20%;
    padding-right: 20%;

    display: flex;

    .set-left {

        width: 15%;

        .user-photo {

            display: flex;
            align-items: center;
            justify-content: center;

            .avatar {
                border-radius: 30%;
                width: 100px;
                height: 100px;
                background-size: cover;
                font-size: 0;
            }
        }

        .user-name {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: large;
        }

        .button-list {
            .account-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .info-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .problem-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }

            .code-button {
                & :hover {
                    background-color: rgb(237, 238, 240);
                }

                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;

                button {
                    width: 100%;
                    height: 40px;
                }
            }
        }


    }

    .set-right {
        margin-left: 5%;
        width: 80%;

        .set-content {

            .content-card {
                border-radius: 10px;

                .info-avatar {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .avatar-input {
                        border-radius: 30%;
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        font-size: 0;
                    }
                }

                .info-user {
                    display: flex;
                    flex-direction: row;

                    margin-top: 15px;

                    .info-name {
                        width: 49%;
                        display: flex;
                        flex-direction: column;
                    }

                    .info-gender {
                        display: flex;

                        flex-direction: column;
                        width: 49%;
                        margin-left: 2%;

                        .gender-button {
                            margin-top: 5px;
                            display: flex;
                            flex-direction: row;

                            button {
                                width: 48%;
                            }

                        }
                    }

                }

                .user-address {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: row;

                    .user-live {
                        display: flex;

                        flex-direction: column;

                        width: 49%;

                        .select {
                            display: flex;
                            flex-direction: row;
                            margin-top: 5px;
                        }

                    }

                    .user-shool {
                        width: 49%;
                        margin-left: 2%;

                    }
                }
            }

            .user-word {
                margin-top: 15px;
                display: flex;
                flex-direction: column;
            }

            .save-button {
                width: 100%;
                margin-top: 20px;

                display: flex;
                justify-content: flex-end;

                button {
                    right: 20px;
                    width: 150px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .set {

        padding-left: 0%;
        padding-right: 0%;

        .set-left {
            display: none;
        }

        .set-right {
            width: 100%;
            margin-left: 1%;
            margin-right: 1%;
        }
    }
}
</style>