<template>
    <div class="login">

        <!-- 左边盒子 -->
        <div class="login-left">

            <!-- LoGo显示栏 -->
            <div class="logobox">
                <div class="imagebox">
                    <img src="@/assets/logo.png" id="avatar" />
                </div>
            </div>

        </div>

        <!-- 右边盒子 -->
        <div class="login-right">

            <!-- 水平居中盒子 -->
            <div class="center-box">

                <!-- 用户状态 -->
                <div class="state">

                    <!-- 登录状态 -->
                    <div class="login-state" @click="nowstate = 0">
                        登录
                    </div>

                    <!-- 注册状态 -->
                    <div class="register-state" @click="nowstate = 1">
                        注册
                    </div>

                    <!-- 滑块 -->
                    <div class="state-box" :style="nowstate == 0 ? 'margin-left: 60px' : 'margin-left: 160px;'"></div>

                </div>

            </div>

            <!-- 水平居中盒子 -->
            <div class="center-box">

                <!-- 用户操作 -->
                <div class="to-do">

                    <!-- 登录盒子 -->
                    <div class="to-login" v-show="nowstate == 0">

                        <!-- 登录form -->
                        <el-form :model="LoginData">

                            <!-- 账号 -->
                            <el-form-item v-show="!emailLogin">
                                <el-input type="text" placeholder="请输入账号" :suffix-icon="User" v-model="LoginData.account"
                                    class="to-input"></el-input>
                            </el-form-item>

                            <!-- 密码 -->
                            <el-form-item v-show="!emailLogin">
                                <el-input type="password" placeholder="请输入密码" :suffix-icon="Lock" show-password
                                    v-model="LoginData.password" class="to-input"></el-input>
                            </el-form-item>

                            <!-- 邮箱 -->
                            <el-form-item v-show="emailLogin">
                                <el-input type="text" placeholder="请输入邮箱" :suffix-icon="Message" v-model="LoginEmail.email"
                                    class="to-input"></el-input>
                            </el-form-item>

                            <!-- 邮箱验证码 -->
                            <el-form-item v-show="emailLogin">
                                <el-input type="text" placeholder="请输入验证码" v-model="LoginEmail.code" style="width: 200px;
                                    height: 40px;"></el-input><el-button style="width: 100px;height: 40px;"
                                    :loading="EmailLoading" @click="sendEmail">{{ EmailSendMessage }}</el-button>
                            </el-form-item>

                            <!-- 登录按钮 -->
                            <el-form-item>

                                <el-button class="to-button" @click="loginverify">
                                    登录
                                </el-button>

                                <el-dialog v-model="tologin" title="验证码">
                                    <div class="dialog-main">
                                        <el-input type="text" placeholder="请输入验证码" v-model="LoginData.code"
                                            style=" width: 70%;height: 40px;"></el-input>
                                        <img id="LoginImg" @click="loginverify" />
                                        <el-button @click="login" class="to-button" style="width: 70%;">确认</el-button>
                                    </div>
                                </el-dialog>

                            </el-form-item>

                        </el-form>

                    </div>

                    <div class="to-register" v-show="nowstate == 1">

                        <!-- 注册form -->
                        <el-form :model="RegisterData">

                            <!-- 邮箱 -->
                            <el-form-item>
                                <el-input type="text" placeholder="请输入邮箱" v-model="RegisterData.email"
                                    :suffix-icon="Message" class="to-input">
                                </el-input>
                            </el-form-item>

                            <!-- 账号 -->
                            <el-form-item>
                                <el-input type="text" placeholder="请输入用户名" :suffix-icon="User"
                                    v-model="RegisterData.username" class="to-input"></el-input>
                            </el-form-item>

                            <!-- 密码 -->
                            <el-form-item>
                                <div style="display: flex;flex-direction: column">
                                    <el-input type="password" placeholder="请输入密码" :suffix-icon="Lock" show-password
                                        v-model="RegisterData.password" class="to-input"></el-input>
                                    <el-text :style="passwordStrength == '强密码' ? 'color:green' : 'color:red'"
                                        style="margin-top: 5px;">
                                        {{ passwordStrength }}</el-text>
                                </div>
                            </el-form-item>

                            <!-- 注册按钮 -->
                            <el-form-item>

                                <el-button class="to-button" @click="registerverify">
                                    注册
                                </el-button>

                                <el-dialog v-model="toregister" title="验证码">
                                    <div class="dialog-main">
                                        <el-input type="text" placeholder="请输入验证码" v-model="RegisterData.code"
                                            style=" width: 70%;height: 40px;"></el-input>
                                        <el-button @click="register" class="to-button" style="width: 70%;">确认</el-button>
                                    </div>
                                </el-dialog>

                                <el-dialog v-model="showuser" title="用户信息" :before-close="handleClose">
                                    <div class="dialog-main">
                                        <text>用户名:{{ UserData.username }}</text>
                                        <text>账号:{{ UserData.account }}</text>
                                        <text>密码:{{ UserData.password }}</text>
                                    </div>
                                </el-dialog>

                            </el-form-item>

                        </el-form>

                    </div>
                </div>

            </div>

            <!--水平居中盒子  -->
            <div class="center-box">

                <!--隐私协议  -->
                <div class="agreement">

                    <el-checkbox v-model="CheckAgreement" label="同意" size="large"></el-checkbox>
                    <a href="/UserAgreement">《隐私协议》</a>

                </div>

            </div>

            <!--水平居中盒子  -->
            <div class="center-box">

                <!-- 其他登录方式 -->
                <div class="other-login" v-show="nowstate == 0">

                    <div class="emaillogin" @click="emailLogin = !emailLogin">
                        <svg t="1698051175257" class="icon" viewBox="0 0 1024 1024" version="1.1" v-show="!emailLogin"
                            xmlns="http://www.w3.org/2000/svg" p-id="14379" width="32" height="32">
                            <path
                                d="M 957.4 688.5 V 318 L 764.1 511.3 l 193.3 177.2 m -326.3 -44.2 c -31.7 31.7 -74.6 49.6 -119.2 49.6 c -45.1 0 -87.5 -17.4 -119.2 -49.6 L 289.2 540.7 l -222.8 204 v 34.4 c 0 44.6 36.2 81.2 80.8 81.2 h 729 c 44.6 0 80.8 -36.2 80.8 -80.8 v -35.3 L 734.7 540.7 L 631.1 644.3"
                                fill="#2ac17e" p-id="14380"></path>
                            <path
                                d="M 877.1 164.4 h -729 c -44.6 0 -80.8 36.2 -80.8 80.8 v 17 l 354.9 354.9 c 24.1 24.1 56.2 37.5 90.6 37.5 s 66.5 -13.4 90.6 -37.5 l 354.9 -354.9 v -17 c 0 -44.2 -36.6 -80.8 -81.2 -80.8 M 65.5 318 v 371.9 l 193.7 -177.7 L 65.5 318"
                                fill="#2ac17e" p-id="14381"></path>
                        </svg>
                        <svg t="1698051351699" class="icon" viewBox="0 0 1024 1024" version="1.1" v-show="emailLogin"
                            xmlns="http://www.w3.org/2000/svg" p-id="18026" width="32" height="32">
                            <path
                                d="M803.7888 488.704H229.3248V344.7808c0-158.3616 128.8704-287.232 287.232-287.232s287.232 128.8704 287.232 287.232v143.9232z m-461.824-112.64H691.2v-31.3344c0-96.256-78.336-174.592-174.592-174.592s-174.592 78.336-174.592 174.592v31.3344z"
                                fill="#F7BC00" p-id="18027"></path>
                            <path
                                d="M780.7488 465.664H252.3648V344.7808c0-145.664 118.528-264.192 264.192-264.192s264.192 118.528 264.192 264.192v120.8832z m-461.824-66.56h395.3152V344.7808c0-109.0048-88.6784-197.632-197.632-197.632-109.0048 0-197.632 88.6784-197.632 197.632v54.3232z"
                                fill="#FFD029" p-id="18028"></path>
                            <path
                                d="M762.8288 447.744h-492.544V344.7808c0-135.7824 110.4896-246.272 246.272-246.272 135.7824 0 246.272 110.4896 246.272 246.272v102.9632z m-461.824-30.72H732.16V344.7808c0-118.8864-96.7168-215.552-215.552-215.552s-215.552 96.7168-215.552 215.552v72.2432z"
                                fill="#FDF2B1" p-id="18029"></path>
                            <path
                                d="M817.5616 954.368H212.992c-73.6256 0-133.3248-59.6992-133.3248-133.3248v-351.232c0-73.6256 59.6992-133.3248 133.3248-133.3248h604.5696c73.6256 0 133.3248 59.6992 133.3248 133.3248v351.2832c0 73.5744-59.6992 133.2736-133.3248 133.2736z"
                                fill="#6D5CF4" p-id="18030"></path>
                            <path
                                d="M793.0368 903.6288H237.4656c-63.5392 0-115.0464-51.5072-115.0464-115.0464V481.8432c0-63.5392 51.5072-115.0464 115.0464-115.0464h555.5712c63.5392 0 115.0464 51.5072 115.0464 115.0464v306.7392c0 63.5392-51.5072 115.0464-115.0464 115.0464z"
                                fill="#756AF6" p-id="18031"></path>
                            <path
                                d="M752.2816 861.7984H278.272c-63.8464 0-115.6096-51.7632-115.6096-115.6096V509.9008c0-63.8464 51.7632-115.6096 115.6096-115.6096h474.0096c63.8464 0 115.6096 51.7632 115.6096 115.6096v236.288c0 63.8464-51.7632 115.6096-115.6096 115.6096z"
                                fill="#8D7AF9" p-id="18032"></path>
                            <path
                                d="M715.776 800.0512H314.7776c-57.7536 0-104.6016-46.7968-104.6016-104.6016v-154.9312c0-57.7536 46.7968-104.6016 104.6016-104.6016h400.9984c57.7536 0 104.6016 46.7968 104.6016 104.6016v154.9312c-0.0512 57.7536-46.848 104.6016-104.6016 104.6016z"
                                fill="#8E88FC" p-id="18033"></path>
                            <path
                                d="M629.76 541.1328H400.7424c-47.9744 0-87.04 39.0656-87.04 87.04s39.0656 87.04 87.04 87.04H629.76c47.9744 0 87.04-39.0656 87.04-87.04s-39.0144-87.04-87.04-87.04z"
                                fill="#6D5CF4" p-id="18034"></path>
                            <path
                                d="M629.76 684.4928H400.7424c-31.1296 0-56.32-25.1904-56.32-56.32s25.1904-56.32 56.32-56.32H629.76c31.1296 0 56.32 25.1904 56.32 56.32s-25.1904 56.32-56.32 56.32z"
                                fill="#F7BC00" p-id="18035"></path>
                            <path d="M629.76 661.4528H400.7424a33.28 33.28 0 1 1 0-66.56H629.76a33.28 33.28 0 1 1 0 66.56z"
                                fill="#FFD029" p-id="18036"></path>
                            <path
                                d="M629.76 643.5328H400.7424c-8.4992 0-15.36-6.8608-15.36-15.36s6.8608-15.36 15.36-15.36H629.76c8.4992 0 15.36 6.8608 15.36 15.36s-6.8608 15.36-15.36 15.36z"
                                fill="#FDF2B1" p-id="18037"></path>
                        </svg>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script setup>
import { ref, reactive, defineEmits, watch } from 'vue'
import { User, Lock, Message } from '@element-plus/icons-vue'
import store from '@/store';
import router from '@/router';
import $ from 'jquery'
import { ElMessage, ElMessageBox } from 'element-plus';

// 操作状态
const nowstate = ref(0)
const tologin = ref(false)
const toregister = ref(false)
const verKey = ref('')
const showuser = ref(false)
const emailLogin = ref(false)

//子组件传递消息
const emit = defineEmits(['visible']);

// 隐私协议
const CheckAgreement = ref(true)

// 登录信息
const LoginData = reactive({
    account: '',
    password: '',
    code: ''
})

// 注册信息
const RegisterData = reactive({
    email: '',
    username: '',
    password: '',
    code: ''
})

// 用户信息
const UserData = reactive({
    username: '',
    account: '',
    password: ''
})

// 邮箱登录
const LoginEmail = reactive({
    email: '',
    code: ''
})


// 是否等待邮箱验证码
const EmailLoading = ref(false)

// 初始化内容
const EmailSendMessage = ref("发送验证码")

// 发送邮箱按钮
const sendEmail = () => {
    if (CheckAgreement.value == false) {
        open("请先同意协议", "error");
        return;
    }
    if (LoginEmail.email == null || LoginEmail.email == "") {
        open("邮箱不能为空", "error");
        return;
    }
    $.ajax({
        url: "https://staroj.top:3010/User/sendEmail",
        type: "get",
        data: {
            email: LoginEmail.email,
        },
        success(resp) {
            if (resp.message == "success") {
                verKey.value = resp.key;
                open("邮箱发送成功，请稍后~", "success");
                EmailLoading.value = true;

                let countdownTime = 60;

                // 创建一个定时器，每秒减少一秒
                let countdown = setInterval(function () {

                    // 更新内容
                    EmailSendMessage.value = countdownTime + "秒后重发"

                    // 判断倒计时是否结束
                    if (countdownTime <= 0) {

                        // 清除定时器
                        clearInterval(countdown);

                        // 倒计时结束的操作
                        EmailLoading.value = false;

                        // 更新内容
                        EmailSendMessage.value = "发送验证码";
                    }

                    // 减少倒计时时间
                    countdownTime--;
                }, 1000);
            }
            else {
                open(resp.message, "error");
            }
        },
        error() {
            open("系统错误~", "error");
        }
    })
}

// 登录验证码
const loginverify = () => {
    if (CheckAgreement.value == false) {
        open("请先同意协议", "error");
        return;
    }

    if (emailLogin.value) {

        // 前端验证
        if (LoginEmail.email == null || LoginEmail.email == "") {
            open("邮箱不能为空~", "error");
            return;
        }
        if (LoginEmail.code == null || LoginEmail.code == "") {
            open("验证码不能为空~", "error");
            return;
        }
        if (verKey.value == null || verKey.value == "") {
            open("请重新申请验证码~", "error");
            return;
        }

        store.dispatch("loginEmail", {
            email: LoginEmail.email,
            code: LoginEmail.code,
            key: verKey.value,
            success() {
                store.dispatch("getinfo", {
                    success() {
                        router.push({ name: 'home' });
                        open("登录成功", "success");
                        tologin.value = false;
                        verKey.value = "";
                        LoginData.account = "";
                        LoginData.password = "";
                        LoginData.key = "";
                        emit('visible', false);
                    },
                    error(resp) {
                        open(resp.message, "error");
                    }
                })
            },
            error(resp) {
                open(resp.message, "error");
            },
            Loginerror(resp) {
                open(resp, "error");
                tologin.value = false;
            }
        }
        )
    } else {

        if (LoginData.account == null || LoginData.account == "") {
            open("账号不能为空~", "error");
            return;
        }

        if (LoginData.password == null || LoginData.password == "") {
            open("密码不能为空~", "error");
            return;
        }

        LoginData.code = "";
        tologin.value = true;
        $.ajax({
            url: "https://staroj.top:3010/User/captcha",
            type: "get",
            data: {
                account: LoginData.account,
            },
            success(resp) {
                verKey.value = resp.key
                $('#LoginImg').attr('src', resp.image)
            }
        })
    }

}

const registerverify = () => {
    if (CheckAgreement.value == false) {
        open("请先同意协议", "error");
        return;
    }
    if (RegisterData.email == null || RegisterData.email == "") {
        open("邮箱不能为空", "error");
        return;
    }
    RegisterData.code = "";
    toregister.value = true;
    $.ajax({
        url: "https://staroj.top:3010/User/sendEmail",
        type: "get",
        data: {
            email: RegisterData.email,
        },
        success(resp) {
            if (resp.message == "success") {
                verKey.value = resp.key;
                open("邮箱发送成功，请稍后~", "success")
            }
            else {
                open("邮箱不能为空", "error");
            }
        },
        error(resp) {
            open(resp, "error");
        }
    });
}

// 普通登录
const login = () => {

    if (LoginData.account == null || LoginData.account == "") {
        open("用户名不能为空", "error");
        return;
    }

    if (LoginData.password == null || LoginData.password == "") {
        open("密码不能为空", "error");
        return;
    }

    if (verKey.value == null) {
        open("验证码错误", "error");
        return;
    }

    // 登录
    store.dispatch("loginPassword", {
        account: LoginData.account,
        password: LoginData.password,
        code: LoginData.code,
        key: verKey.value,
        success() {
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: 'home' });
                    open("登录成功", "success");
                    tologin.value = false;
                    verKey.value = "";
                    LoginData.account = "";
                    LoginData.password = "";
                    LoginData.key = "";
                    emit('visible', false);
                },
                error(resp) {
                    open(resp.message, "error");
                }
            })
        },
        error(resp) {
            open(resp.message, "error");
            loginverify();
        },
        Loginerror(resp) {
            open(resp, "error");
            tologin.value = false;
        }
    });
}

const register = () => {

    if (verKey.value == null) {
        open("验证码错误~", "error");
        return;
    }

    if (RegisterData.email == null || RegisterData.email == "") {
        open("邮箱不能为空~", "error");
        return;
    }

    if (RegisterData.username == null || RegisterData.username == "") {
        open("用户名不能为空~", "error");
        return;
    }

    if (RegisterData.code == null || RegisterData.code == "") {
        open("邮箱验证码不能为空~", "error");
        return;
    }

    if (verKey.value == null || verKey.value == "") {
        open("请重新申请验证码~", "error");
        return;
    }

    $.ajax({
        url: "https://staroj.top:3010/User/register",
        type: "post",
        data: {
            email: RegisterData.email,
            username: RegisterData.username,
            password: RegisterData.password,
            code: RegisterData.code,
            key: verKey.value,
        },
        success(resp) {
            if (resp.message == "success") {
                router.push({ name: 'home' });
                toregister.value = false;
                UserData.account = resp.account;
                UserData.username = RegisterData.username;
                UserData.password = RegisterData.password;
                RegisterData.username = "";
                RegisterData.password = "";
                RegisterData.confirmpassword = "";
                RegisterData.key = "";
                open("注册成功", "success");
                showuser.value = true;
            } else {
                verKey.value = "";
                RegisterData.code = "";
                open(resp.message, "error");
            }
        }
    })
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}

const handleClose = () => {
    ElMessageBox.confirm('请认真保存您的账号密码')
        .then(() => {
            showuser.value = false;
        })
        .catch(() => {
            // catch error
        })
}

const checkPasswordStrength = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[`~!@#$%^&*()-=_+;':",./<>?])(?=\S+$).{6,32}$/;

    if (password == "") {
        return "";
    }

    if (passwordRegex.test(password)) {
        return "强密码";
    } else {
        return "弱密码";
    }
}

const passwordStrength = ref();

watch(RegisterData, () => {
    passwordStrength.value = checkPasswordStrength(RegisterData.password)
})
</script>

<style lang="less" scoped>
// 水平居中
.center-box {
    display: flex;
    justify-content: center;
}

// 输入
.to-input {

    // 大小
    width: 300px;
    height: 40px;
}

// 确定按钮
.to-button {
    width: 300px;
    background-image: linear-gradient(to right, #87CEEB, #B0E0E6);
    margin-top: 10px;
    height: 40px;
}

.dialog-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login {

    // 登录界面大小
    width: 800px;
    height: 500px;

    /* 设置圆角 */
    border-radius: 8px;

    // 延迟动画
    transition: all ease 0.5s;

    // 横向排列
    display: flex;

    // 左边盒子 
    .login-left {

        border-radius: 8px;

        // 占比大小
        width: 40%;
        height: 100%;

        // 盒子边距线
        box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);

        // 背景颜色
        background-color: rgb(245, 252, 250);

        .logobox {

            // LOGO框大小
            width: 200px;
            height: 200px;

            // 边界距离
            margin: 20px auto;

            // 设置为圆形 
            border-radius: 50%;

            // 设置用户禁止选中 
            user-select: none;

            // LOGO边界线隐藏
            overflow: hidden;

            // 相对位置
            position: relative;

            //距离顶部距离
            top: 20%;

            .imagebox {

                // LOGO宽度
                width: 100%;

                // 延迟动画
                transition: 0.5s;
            }
        }
    }

    // 右边盒子
    .login-right {

        // 占比大小
        width: 60%;
        height: 100%;

        // 水平居中
        display: flex;
        flex-direction: column;

        // 用户状态
        .state {

            // 顶部边距
            margin-top: 10%;

            // 水平两侧对齐,竖直居中
            display: flex;
            justify-content: space-between;
            align-items: center;

            // 背景颜色
            background-color: rgb(240, 242, 245);

            // 大小
            width: 300px;
            height: 50px;

            // 登录状态
            .login-state {

                // 左边距
                margin-left: 50px;

                // 大小
                width: 100px;
                height: 40px;

                // 水平竖直居中
                display: flex;
                justify-content: center;
                align-items: center;

                // 图层逻辑
                z-index: 1;

                // 设置用户禁止选中 
                user-select: none;
            }

            // 注册状态
            .register-state {

                // 右边距
                margin-right: 50px;

                // 大小
                width: 100px;
                height: 40px;

                // 水平竖直居中
                display: flex;
                justify-content: center;
                align-items: center;

                // 图层逻辑
                z-index: 1;

                // 设置用户禁止选中 
                user-select: none;
            }

            // 滑块
            .state-box {

                // 位置逻辑
                position: absolute;

                // 大小
                width: 80px;
                height: 40px;

                // 背景颜色
                background-color: white;

                // 图层逻辑
                z-index: 0;

                // 圆弧边界
                border-radius: 4px;

                //延迟动画
                transition: all ease 0.4s;

                // 边距初始状态
                margin-left: 60px;
            }

        }

        // 用户操作
        .to-do {

            // 水平居中
            display: flex;
            justify-content: center;

            // 登录距离
            margin-top: 30px;

            .email-login {
                color: green;
                font-size: smaller;

            }

        }

        // 隐私协议
        .agreement {

            // 竖直对齐
            display: flex;
            align-items: center;

            // 宽度
            width: 300px;

            a {

                // 字体大小
                font-size: small;

                // 超链接下划线
                text-decoration: none;

                // 字体颜色
                color: black;

                // 设置用户禁止选中 
                user-select: none;
            }

            span {

                // 字体大小
                font-size: small;

                // 设置用户禁止选中 
                user-select: none;
            }
        }

        // 其他登录方式
        .other-login {

            // 水平对齐
            display: flex;

            // 宽度
            width: 300px;

            // 顶部边距
            margin-top: 10px;

            // 左边界
            .wxlogin {
                margin-left: 20px;
            }

            .emaillogin {
                margin-left: 20px;
            }
        }

    }
}

// 小窗口样式
@media (max-width: 992px) {

    .login {

        // 宽度
        width: 450px;

        // 居中对齐
        display: flex;
        justify-content: center;

        // 隐藏
        .login-left {
            display: none;
        }

        // 占比
        .login-right {
            width: 100%;
        }
    }
}
</style>